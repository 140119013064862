/*********************************************************************************************************/
/*       Bootstrap overrides for generic styling                                                         */
/*       (./sled/frontend/vendor/bootstrap-sass/assets/stylesheets/bootstrap/_scaffolding.scss)          */
/*********************************************************************************************************/

/*
    Set Default/Base Fonts
*/

html {
    font-family: 'Udemy-Sans';
    font-weight: 400;
    font-size: 10px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;
}
body {
    color: #444444;
    font-family: 'Udemy-Sans', 'sf pro text', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'segoe ui', 'Helvetica', 'Arial', 'sans-serif', 'apple color emoji', 'segoe ui emoji', 'segoe ui symbol' !important;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.4;
}

.page-header {
    padding-bottom: 0;
    margin: 0 0 20px 0;
    border-bottom: none;
}

// Force Udemy Bold on table (tablesaw) headings
table.tablesaw {
    thead {
        font-family: 'Udemy-Sans-Bold';
        font-weight: 700;
    }
}

.form-control {
    font-size: 1.4rem;
}

.help-block {
    font-size: 1.4rem;
}


/*
    wide-titlebar is used on pages like backpack and syllabus
*/

.wide-titlebar { position: relative; margin: 20px -35px 30px -35px; padding: 0 35px 5px; border-bottom: 10px solid #000; }
.wide-titlebar.wide-titlebar-with-breadcrumbs { margin-top: 0; }
.wide-titlebar .byline { margin: 0; }
.wide-titlebar h1 { margin: 0; }
.wide-titlebar .wide-titlebar-title { color: #000; margin: 0; font-size: 1.9rem; line-height: 30px; font-weight: 700; }
.wide-titlebar .nav { margin-bottom: 0; }
.wide-titlebar .nav-tabs { border-bottom: 0; }
.wide-titlebar .nav-tabs>li { margin-bottom: 0; text-transform: capitalize; font-size: 1.3rem; font-weight: 700; letter-spacing: 0.125em; }
.wide-titlebar .nav-tabs>li>a:hover, #backpack-titlebar .nav-tabs>li>a:focus { border-color: transparent; }

.control-group.disabled,
.control-group.disabled label,
.control-group.disabled input,
.control-group.disabled .help-inline {
    color: #aaa;
    cursor: not-allowed;
}

.well {
    border-radius: 0;
    box-shadow: inset 0px 7px 7px -5px rgba(0,0,0,0.2);
    border-bottom-width: 0;
}


/*
    Use .inverted when you want an element with flipped foreground/background colors
*/

.inverted { color: #ffffff; background-color: #1C1D1F; }
.inverted * { color: inherit; background-color: inherit; }
.inverted .progress { background-color: #f7f7f7; }
.inverted [class^="icon-"], .inverted [class*=" icon-"] { background-color: transparent; }
.inverted a, .inverted a:hover, .inverted a:focus { color: #ffffff; }
.inverted .color-primary-emphasis a { color: inherit; text-decoration: underline; }
.inverted .label-important, .inverted .badge-important { background-color: #b94a48; }


/*
    General helpers 
*/

.byline { font-size: 1.1rem; color: #000; text-transform: capitalize; margin: 0.25em 0; }
.inverted .byline { color: #fff; }
.byline .course-status { font-weight: 700; color: #b94a48; }
.byline .community-status { font-weight: 700; color: #b94a48; }

.selectjs,
.dropdown  {
    position: relative;
    margin: 0;
    padding: 0;
}
li.selectjs a,
li.dropdown a {
    position: relative;
    padding-right: 26px;
}
.selectjs .subnav,
.dropdown .subnav {
    overflow: hidden;
    position: absolute;
    top: 28px;
    left: 0;
    width: auto;
    padding: 0;
    margin: 0;
    z-index: 9999;
    border: 1px solid #6A6F73;
    -webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, 0.68);
    -moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, 0.68);
    box-shadow:         0px 2px 5px rgba(50, 50, 50, 0.68);
}
.selectjs .subnav li,
.dropdown .subnav li {
    display: block;
    float: left;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin: 0;
    padding: 0;
}
.selectjs .subnav li.separator,
.dropdown .subnav li.separator {
    border-top: 1px solid #aaa;
}
.dropdown-container .dropdown .subnav li a,
.selectjs .subnav li a,
.dropdown .subnav li a {
    display: block;
    margin: 0;
    padding: 4px 5%;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 0;
    border: none;
    cursor: pointer;
}
.selectjs .subnav li a:hover,
.dropdown .subnav li a:hover {
    text-decoration: none;
    background-color: #f0f0f0;
}
.selectjs .selectjs-trigger,
.dropdown-trigger {
    line-height: 24px;
    padding: 0 20px 0 8px;
    background-color: white;
    border: 1px solid #ddd;
    cursor: pointer;
}

.__likes__ .user_liked { color: green; }
.__likes__ .user_disliked { color: #ca4943; }

a.read-more { cursor: pointer; white-space: nowrap; }

#topMenu {
    position: relative;
    margin-bottom: 10px;
}
#topMenu > ul {
    margin: 0px;
}
#topMenu > ul > li {
    float: right;
    position: relative;
    border-left: 1px solid #cccccc;
    color: inherit;
    z-index: 501;
}
#topMenu > ul > li.first {
    float: none;
    border-left: none;
    cursor: pointer;
    background-color: transparent;
    z-index: 500;
}
.ie7 #topMenu li.first { z-index: 10000; }
#topMenu li.login { line-height: 44px; }
#topMenu > ul > li > a {
    padding: 15px;
    display: block;
    color: inherit;
}
#topMenu > ul > li > a > i {
    font-size: 2.0rem;
}
#topMenu > ul > li.first > a {
    padding: 4px 10px;
}
#topMenu a:hover { text-decoration: none; }
#topMenu #userDropdownTrigger { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
#topMenu ul.subnav { top: 53px; }
#topMenu ul.subnav li a { border-bottom: 0; padding: 5px 15px; }
#topMenu ul.subnav li a i { display: inline-block; width: 45px; text-align: center; }
#topMenu ul.subnav i.icon-globe { font-size: 1.8rem; }


/*
    Media Queries
*/

@media (max-width: 767px) {
    /* This reworks the narrow design so elements outside of .containers can go edge-to-edge */
    body {
        padding-left: 0;
        padding-right: 0;
    }
    .container {
        padding-left: 20px;
        padding-right: 20px;
    }
}
