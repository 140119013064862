//
// Border
//

/*
  Additive

  <span class="border"></span>
  <span class="border-top"></span>
  <span class="border-right"></span>
  <span class="border-bottom"></span>
  <span class="border-left"></span>

  Subtractive
  <span class="border-0"></span>
  <span class="border-top-0"></span>
  <span class="border-right-0"></span>
  <span class="border-bottom-0"></span>
  <span class="border-left-0"></span>

  Responsive
  Responsive variations also exist for each boarder value
  <span class="border-left-0 border-sm-left"></span>

  Border-radius
  <img src="..." alt="..." class="rounded">
  <img src="..." alt="..." class="rounded-top">
  <img src="..." alt="..." class="rounded-right">
  <img src="..." alt="..." class="rounded-bottom">
  <img src="..." alt="..." class="rounded-left">
  <img src="..." alt="..." class="rounded-circle">
  <img src="..." alt="..." class="rounded-0">

*/

.border         { border: $border-width solid $border-color !important; }
.border-top     { border-top: $border-width solid $border-color !important; }
.border-right   { border-right: $border-width solid $border-color !important; }
.border-bottom  { border-bottom: $border-width solid $border-color !important; }
.border-left    { border-left: $border-width solid $border-color !important; }

.border-0        { border: 0 !important; }
.border-top-0    { border-top: 0 !important; }
.border-right-0  { border-right: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-left-0   { border-left: 0 !important; }

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (border: border) {
        
        .#{$abbrev}#{$infix}-top     { border-top: $border-width solid $border-color; }
        .#{$abbrev}#{$infix}-right   { border-right: $border-width solid $border-color; }
        .#{$abbrev}#{$infix}-bottom  { border-bottom: $border-width solid $border-color; }
        .#{$abbrev}#{$infix}-left    { border-left: $border-width solid $border-color; }

        .#{$abbrev}#{$infix}-0 { border: 0 !important; }
        .#{$abbrev}#{$infix}-top-0 { border-top: 0 !important; }
        .#{$abbrev}#{$infix}-bottom-0 { border-bottom: 0 !important; }
        .#{$abbrev}#{$infix}-left-0 { border-left: 0 !important; }
        .#{$abbrev}#{$infix}-right-0 { border-right: 0 !important; }
    }
  }
}

//
// Border-radius
//

.rounded {
  border-radius: $border-radius-base !important;
}
.rounded-top {
  border-top-left-radius: $border-radius-base !important;
  border-top-right-radius: $border-radius-base !important;
}
.rounded-right {
  border-top-right-radius: $border-radius-base !important;
  border-bottom-right-radius: $border-radius-base !important;
}
.rounded-bottom {
  border-bottom-right-radius: $border-radius-base !important;
  border-bottom-left-radius: $border-radius-base !important;
}
.rounded-left {
  border-top-left-radius: $border-radius-base !important;
  border-bottom-left-radius: $border-radius-base !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}