.float-right {
  float: right;

  &:after {
    content: ' ';
    display: table;
    height: 1px;
    clear: both;
  }
}

.clearfix {
  clear: both;
}