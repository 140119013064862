.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file,
.fileinput-exists.input-group .btn-file,
.fileinput-exists .input-group .btn-file {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-image: url('../../../../assets/images/button-transparency.png');
  background-repeat: repeat-x;
  background-position: 50% 50%;
  font-family: 'Udemy-Sans', 'sf pro text', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'segoe ui', 'Helvetica', 'Arial', 'sans-serif', 'apple color emoji', 'segoe ui emoji', 'segoe ui symbol';
  color: #ffffff;
  background-color: #6A6F73;
  text-shadow: none;

  &:hover { background-image: url('../../../../assets/images/button-transparency-90deg.png'); text-decoration: none; }
  &:active { background-image: url('../../../../assets/images/button-transparency-90deg.png'); padding-top: 6px; padding-bottom: 2px; text-decoration: none; }
}

.fileinput-filename {
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    /* new lines */
    width: 100%;
    position: absolute;
    left: 0;
    padding-left: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
}