#pageTopBar {
  @media screen and (max-width: $screen-xs-max){
    padding-left: 0;
    padding-right: 0;
  }
}

#topMenu {
  // background-color: $menu-color;
  margin-bottom: 1rem;
  position: relative;
  width: 100%;

  @media screen and (min-width: $mobile-menu-break){
    width: 370px;
  }

  .unstyled {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  li {
    border-left: 1px solid $link-color;
    display: inline-block;
    float: right;
    line-height: 20px;
    vertical-align: top;
    z-index: 1000;

    @media screen and (min-width: $mobile-menu-break){
      position: relative;
    }

    > a {
      color: $link-color;
      display: block;
      padding: 15px;
      text-decoration: none;

      > i { font-size: 2rem; }
    }

    &.first {
      background-color: transparent;
      border-left: 0;
      cursor: pointer;
      display: block;
      float: none;
      z-index: 500;

      + a {
        overflow: hidden;
      }

      > a {
        padding: 4px 10px;
      }
    }

    &:hover > .subnav {
      display: block;
    }
  }

  .subnav {
    background-color: $menu-color;
    border: 1px solid #dddddd;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.3);
    display: none;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 51px;
    width: 100%;
    z-index: 9999;

    @media screen and (min-width: $mobile-menu-break){
      width: 200px;
    }

    &.supportSubnav {
      left: 0;
      right: auto;
      width: 100%;

      @media screen and (min-width: $mobile-menu-break){
        left: auto;
        right: 0;
        width: 300px;
      }
    }

    li {
      border-left: 0;
      display: block;
      float: none;
      margin: 0;
      padding: 0;

      &:hover {
        a {
          background-color: #fff;
          color: $menu-color;
        }
      }

      a {
        float: none;
        display: block;
        white-space: nowrap;
        padding: 5px 15px;
        line-height: 3em;

        i {
          width: 45px;
          display: inline-block;
          text-align: center;
        }
      }
    }
  }
}

.supportSubnav {
  color: #fff;

  li a {
    line-height: inherit;
    padding: 0;
  }
}

#supportDropdown {
  padding: 30px;

  @media(min-width: $mobile-menu-break){ padding: 30px;}

  .optionIcon {
    float: left;
    padding-top: 5px;

    i {
      width: 30px;
      text-align: center;
    }
  }

  .supportOption {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  .optionLabel {
    font-family: 'Udemy-Sans-Bold';
    font-size: 1.1rem;
    font-weight: 700;
  }

  .optionSubLabel {
    font-size: 1rem;
    font-style: italic;
  }

  .optionValue {
    font-size: 1rem;
    padding: 5px 0 0 5px;

    a { padding: 0; line-height: 1em; }
    a:hover { background-color: none; }
  }
}


