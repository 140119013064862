/* Define fonts path */
$font-path: '../../../../../sled/media/fonts' !default;

/* Udemy Sans */
@font-face {
    font-family: 'Udemy-Sans';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: local('Udemy Sans Regular'), local('Udemy-Sans'), url('#{$font-path}/Udemy-Sans-Regular-v1.woff2') format('woff2');
}
@font-face {
    font-family: 'Udemy-Sans';
    font-style: normal;
    font-weight: 700;
    font-display: block;
    src: local('Udemy Sans Bold'), url('#{$font-path}/Udemy-Sans-Bold-v1.woff2') format('woff2');
}
@font-face {
    font-family: 'Udemy-Sans-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: block;
    src: local('Udemy-Sans-Bold'), url('#{$font-path}/Udemy-Sans-Bold-v1.woff2') format('woff2');
}

/* Suisse Works */
@font-face {
    font-family: 'SuisseWorks';
    font-style: normal;
    font-weight: 700;
    font-display: block;
    src: local('SuisseWorks'), url('#{$font-path}/SuisseWorks-Bold-v1.woff2') format('woff2');
}
