#userhome {

    @media(max-width: 768px){
        padding: 0;
    }
    .userhome-link {
        float: right;
        padding: 3px 8px;
        color: $gray-500;
    }

    .userhome-header {
        border-bottom: 1px solid $gray-200;

        // Set Default Font Size for Font Awesome User Home Header Items
        .fa-th-large,
        .fa-th {
            font-size: 1.5rem;
        }
    }

    .userhome-header-text {
        color: $gray-500;
        font-family: 'SuisseWorks', Georgia, Times, 'times new roman', serif, 'apple color emoji', 'segoe ui emoji', 'segoe ui symbol';
        font-weight: 700;
        float: left;
        font-size: 2.4rem;
        letter-spacing: -.02rem;
        line-height: 1.35;
        padding-top: 3px;
        padding-left: 8px;
        text-transform: capitalize;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .userhome-container-list {
        display: flex;
        flex-flow: column;
    }

    .userhome-container {
        border: 1px solid $gray-200;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .status-type {
        margin-bottom: 10px;

        h6,
        .ud-heading-md {
            color: $gray-400;
            display: inline-block;
        }

        i.fas,
        i.fak {
            color: $gray-500;
        }
    }

    .status-type i.fas,
    .status-title i.fas:not(.vlist) {
        /* font-size: inherit; */
        border-radius: 50%;
        display: inline-block;
    }

    .userhome-item-list {
        display: flex;
        flex-flow: column;
    }

    .userhome-item {
        display: flex;
        flex-direction: column;
        @media(min-width: 768px){
            flex-direction: row !important;
            .status-container {
                border-bottom: 0px !important;
                border-right: 1px solid $gray-200;
            }
          }
        flex-grow: 1;
        border: 1px solid $gray-200;
        padding: 15px 0;
        margin-left: 0;
        margin-right: 0;
    }

    .h-row {
        transition: all 0.3s ease-in-out;
        position: absolute;
        border-radius: 4px 4px 0 0;
        right: 3rem;
        bottom: 20px;
        margin: 0rem 1rem;
        height: 4rem;
        padding-right: 3rem;
    }

    .hover-message {
        border-right: 0px !important;
        font-family: 'Udemy-Sans-Bold';
        font-weight: 700;
        margin: 0 !important;
        opacity: 0;
        padding: 1rem 0 0 3rem;
        text-align: center;
        text-transform: capitalize;
        transition: all ease-in-out 0.3s;
    }


    .userhome-container-item .userhome-item-list:hover {
        text-decoration: none;
        .row+.h-row {
            background-color: $gray-500;
            border: 1px solid $gray-500;
            color: $white;
            i.fas,
            i.fak,
            *,
            .status-title {
                color: $gray-200;
            }
        }
        .row+.h-row {
           
        }
        .h-row .hover-message {
            display: flex !important;
            opacity: 100;
        }

        // [class^="col-md-"]{
        //     display: none;
        // }
    }

    .userhome-container-item .h-row:hover .hover-message {
        display: flex !important;
        opacity: 100;
    }

    .userhome-item .status-text {
        color: $gray-300 !important;
        font-family: 'Udemy-Sans-Bold';
        font-size: 3rem;
        font-weight: 700;
        padding: 15px 0;
        text-align: center;
        vertical-align: middle;
    }

    .userhome-item .status-image-container {
        flex-direction: column;
        text-align: center;
        vertical-align: middle;
        padding: 0;
        min-width: 160px;
        order: last;
    }

    .userhome-item .status-image-container .status-image {
        margin: -4px;
        font-size: 1rem;
    }

    .userhome-item .status-image-container .status-image-text .h4,
    .userhome-item .status-image-container .status-image-text h4 {
        font-family: 'Udemy-Sans-Bold';
        font-weight: 700;
        color: $gray-300 !important;
        text-transform: capitalize !important;
        line-height: 1.5;
    }

    .userhome-item .status-image-container .status-image-text .h5,
    .userhome-item .status-image-container .status-image-text h5 {
        color: $gray-300 !important;
        font-family: 'Udemy-Sans-Bold';
        font-weight: 700;
        text-transform: capitalize !important;
        line-height: 1.5;
    }

    .userhome-item .status-image-container .status-image-text .ud-heading-md,
    .userhome-item .status-image-container .status-image-text h6 {
        color: $gray-300 !important;
        font-family: 'Udemy-Sans-Bold';
        font-size: 1.5rem;
        font-weight: 700;
        text-transform: capitalize !important;
        line-height: 1.5;
    }

    .userhome-item .status-container {
        flex-direction: column;
        border-bottom: 1px solid $gray-200;
    }
    .userhome-item .status-image-text {
        color: $gray-300 !important;
        font-family: 'Udemy-Sans-Bold';
        font-weight: 700;
        margin: 0 0.5rem
    }

    .userhome-item .status-container .status-type {
        font-family: 'Udemy-Sans';
        font-weight: 400;
        font-size: 1.5rem;
        text-transform: capitalize;
    }

    .userhome-item .status-container .status-title {
        color: $gray-400;
        font-family: 'Udemy-Sans-Bold';
        font-weight: 700;
        font-size: 1.8rem;
        margin: 0;
    }

    .status-meta {
        color: $gray-300;
        
        .item {
            padding: 10px 0 0;
        }

        i {
            padding-right: 5px;
            color: $gray-300;
        }
    }

    .icon-border {
        border: 1px;
    }

    .cux-tile .tile-item:hover {
        text-decoration: none;
    }


    .cux-tile.not-clickable {
        cursor: default;
    }

    .cux-tile.support-options {
        flex: 1 1 0;
    }

    .support-item-list {
        display: flex;
        flex-flow: row;
    }

    .support-item {
        flex: 1 1 0;
    }

    .tile-header h4,
    .tile-header .h4 {
        color: $gray-500;
    }

    /* listview sitch */

    .vlist {
        display: none;
    }

    .glist {
        display: block;
    }

    .view-radio+label {
        margin: 0 1rem;
    }

    .view-radio:checked+label,
    .view-radio+label:hover,
    .view-radio+label:focus {
        color: $brand-300;
        cursor: pointer;
    }
    .view-radio:focus+label {
        outline: 1px solid $gray-500;        
    }

    // start list view styles
    #userhome-todo.list-view-radio {

        // to reverse row
        // .userhome-item {
        //     flex-direction: row-reverse;
        //     .status-container {
        //         border-right: none !important;
        //     }
        //     .status-image-container {
        //         border-right: 1px solid $gray-200;                
        //     }
        // }

        .status-type,
        .status-meta {
            display: none;
        }

        .status-image-text,
        .status-image-text .h4 {
            .h4:not(.glist),
            .h5:not(.glist),
            .ud-heading-md:not(.glist) {
                color: $gray-300 !important;
                display: inline-block !important;
            }
        }

        .vlist {
            display: block;

            &.fas,
            &.fak {
                display: inline-block;
                padding-right: 1rem;
            }
        }

        .glist {
            display: none
        }

        .status-title {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}
