/* Used by the video player for responsiveness */

/* youtube jwplayer fix */
.video-container-youtube > div.yt-embed-container {
    position: relative !important;
}
.yt-embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden }
.yt-embed-container iframe, .yt-embed-container object, .yt-embed-container embed { 
    position: absolute; top: 0; left: 0; width: 100%; height: 100%; 
}
.video-container-youtube .jw-display-icon-container,
.video-container-youtube .jw-icon-display { 
    pointer-events:none !important; 
}

.video-container-viddler { position: relative; padding-bottom: 56.25%; padding-top: 20px; height: 0; overflow: hidden; margin: 30px 0; clear: both; }
.video-container-viddler .video-container { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
.video-container-progressive, .video-container-rtmp, .video-container-corpu, .video-container-botr, .video-container-youtube { 
    position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; margin: 0px 0 30px; clear: both; 
}
.video-container-progressive > div, .video-container-rtmp > div, .video-container-corpu > div, .video-container-botr > iframe, .video-container-youtube > div { 
    position: absolute !important; top: 0; left: 0; 
}
.video-container-rtmp { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; margin: 30px 0; clear: both; }
.video-container-rtmp .video-container { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
.video-container-corpu .video-processing { position: relative; width: 100%; height: 100%;}

.video-container-corpu .video-processing .video-processing-text {
    background-color:black;
    background: rgba(0,0,0,.75);
    color:white;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    position: absolute;
    left: 35%; 
    top: 40%;
}

.event_details .video-container-corpu .video-processing .video-processing-text {
    left: 25%; 
    top: 30%;
}

.welcome-video-container {
    margin-bottom: $grid-gutter-width;
}
