/*

  Layout

*/
a:focus { 
    outline: 5px auto #b4690e;
    outline-offset: 5px;
}

html,body {
    height: 100%;
}
body {
    padding-top: 56px;
    display: flex;
    flex-direction: column;
    @media(min-width: $mobile-menu-break) {
        padding-top: 85px;
        background-color: #FFFFFF;
    }
}

// correction for buttons
.inverted i.cux {
    background-color: transparent;
}

.skip-nav {
    position: absolute;
    left: 50%;
    top: -5em;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
    background: #1c1d1f;
    color: #fff !important;
    padding: 0.75em;
    white-space: nowrap;
    z-index: 19;
    opacity: 0;

    &:focus {
        top: 0;
        opacity: 1;
    }
}
body:not(.admin){
    #main, header, footer, .alert {
        max-width: 2560px;
    }
}
#main, header, footer, .alert {
    margin: 0 auto;
    width: 100%;
}
#main {
    flex: 1 0 auto;
    // padding: $mainPadding;
    padding: 0;

    @media(max-width: $screen-desktop) {
        padding: ($grid-gutter-width/2);
    }

    @media screen and (min-width: $mobile-menu-break) {
        margin: 0 auto;
        // background-color: white;
    }
}

.ie7 #main,
.ie8 #main {
    border: 1px solid #ccc;
}

#logo {
    margin: 20px 0;
}

.ie7 #logo {
    padding-bottom: 20px;
}

#sidebar,
#community-sidebar {
    padding: 30px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid white;
}

#sidebar>div:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

#main-tabs {
    margin: 0 auto;
}

#main-tabs.nav-tabs {
    border-bottom: 0;
}

#main-tabs.nav-tabs>li>a {
    padding: 10px 20px;
}

#main-tabs.nav-tabs>li.active>a {
    font-family: 'Udemy-Sans-Bold';
    font-weight: 700;
}

ul {
    padding-left: 1em;
}

/* Pulled from BS2 */
.form-actions {
    // position: fixed;
    // bottom: 0;
    // min-width: 400px;
    // border-top: 1px solid gray;
    // margin: 0;
    // left: 60px;
    // z-index: 100;
    // right: 0;
    padding: 19px 20px 20px;
    background-color: #F7F9FA;
    // box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.4);
}

.form-actions:before,
.form-actions:after {
    display: table;
    content: "";
    line-height: 0;
}

.form-actions:after {
    clear: both;
}

/*
    Admin Styles required as they can be drawn either from master_v2
    or from admin_v3
  */

/* Admin nav tabs */
.cux-nav-tabs {
    margin-bottom: 30px;
    border-bottom: 1px solid #F7F9FA;

    >li {
        float: left;
        margin-bottom: -1px;
    }

    >li.active>a {
        border-bottom: 3px solid #dddddd;
    }

    >li:hover>a {
        background-color: transparent;
        border-bottom: 3px solid #F7F9FA;
    }

    >li>a {
        margin-right: 2px;
        line-height: 1.428571429;
        border: 1px solid transparent;
        border-radius: 4px 4px 0 0;
    }
}

/* Breakout Groups */
.breakout-groups-container {

    /* Drop-hover indicator changes */
    .unassigned-users ul.member-list.drop-hover li.ghost {
        background-color: #ffdddd;
        color: #b00;
        border-color: #b00;
    }

    .unassigned-users ul.member-list.drop-hover {
        background-color: #fdd;
    }

    /* Add group form */
    #add-group {
        form {
            width: 100%;
            padding: 10px 10px 10px 30px;
        }
    }
}

/* jQuery-UI Date Picker icon color override */
.ui-datepicker .ui-widget-header {
    .ui-icon {
        background-image: url("../../../../assets/images/ui-icons_ffffff_256x240.png");
    }

    .ui-state-hover .ui-icon {
        background-image: url("../../../../assets/images/ui-icons_555555_256x240.png")
    }
}

// homepage styling
.status-image>i {
    color: inherit;
}

// remove legacy BS3 style for mobile devices
.dropdown-backdrop {
    display:none;
}

/**
  Base Override for JQuery UI Fix.
  ex. Jquery UI 1.11->1.12 added .ui-menu-item-wrapper's to the underlying elements.
  This broke the conventional styling edits. This fix is a patch for that issue.
*/
.ui-menu-item-wrapper {
    display: block;
  }

.show-sr-only {
  display: block;
  height: auto !important;
  width: auto !important;  
  clip: auto !important;
  position: inherit !important;
  background-color: lime !important;
  border: 1px solid black !important;
}
