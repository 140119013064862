// Type display classes
.display-1 {
  font-size: $font-size-h1;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-1_25 {
  font-size: $font-size-h1*1.25;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-1_5 {
  font-size: $font-size-h1*1.5;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-1_75 {
  font-size: $font-size-h1*1.75;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-1_2 {
  font-size: $font-size-h1*2;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

.display-2 {
  font-size: $font-size-h2;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-2_25 {
  font-size: $font-size-h2*1.25;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-2_5 {
  font-size: $font-size-h2*1.5;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-2_75 {
  font-size: $font-size-h2*1.75;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-2_2 {
  font-size: $font-size-h2*2;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

.display-3 {
  font-size: $font-size-h3;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-3_25 {
  font-size: $font-size-h3*1.25;
  font-weight: $headings-font-weight;
}
.display-3_5 {
  font-size: $font-size-h3*1.5;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-3_75 {
  font-size: $font-size-h3*1.75;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-3_2 {
  font-size: $font-size-h3*2;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

.display-4 {
  font-size: $font-size-h4;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-4_25 {
  font-size: $font-size-h4*1.25;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-4_5 {
  font-size: $font-size-h4*1.5;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-4_75 {
  font-size: $font-size-h4*1.75;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-4_2 {
  font-size: $font-size-h4*2;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
