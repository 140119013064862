.pagination { height: 30px; }

/* bootstrap override */
.has-success {
  .form-control {
    border: 1px solid $gray-500;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

    &:focus {
      border: 1px solid $gray-500;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }
  }
}
.form-control:focus {
  border: 1px solid $gray-500;
  box-shadow: none;
  outline: 5px auto $orange-400;
}

input,
select,
textarea,
.form-control,
.tinymce,
.mce-container {
  @media(max-width: $screen-xs){
    font-size: 1.6rem;
  }

  &.disabled, &[disabled] {
    opacity: .45;
  }
}

input,
select,
textarea {
  font-family: 'Udemy-Sans', 'sf pro text', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'segoe ui', 'Helvetica', 'Arial', 'sans-serif', 'apple color emoji', 'segoe ui emoji', 'segoe ui symbol';
}

input[type="number"],
input[type="text"],
input[type="email"],
input[type="password"],
input[type="file"],
textarea,
.uneditable-input,
select,
select.form-control,
textarea.form-control,
.form-control {
  background-color: $white;
  color: $gray-500;
  border: 1px solid $gray-500;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;

  @media(max-width: $screen-xs-max) {
    font-size: 1.6rem;
  }
}

input[type="search"] {
  background-color: $white;
  border: 1px solid $gray-500;
  box-sizing: border-box;
  color: $gray-500;
  height: 30px;
  font-size: 1.4rem;
  padding: 4px 6px;
  line-height: 20px;
}

.input-prepend.input-append input+.btn-group .btn, .input-prepend.input-append select+.btn-group .btn, .input-prepend.input-append .uneditable-input+.btn-group .btn,
.input-append .add-on:last-child, .input-append .btn:last-child, .input-append .btn-group:last-child>.dropdown-toggle,
.input-prepend.input-append .add-on:first-child, .input-prepend.input-append .btn:first-child,
.input-prepend.input-append .add-on:last-child, .input-prepend.input-append .btn:last-child,
.input-prepend .add-on:first-child, .input-prepend .btn:first-child,
.input-append input, .input-append select, .input-append .uneditable-input,
body .fileupload-new .input-append .btn-file {
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
}

/* Form Layout */
fieldset {
  padding: 15px 30px;
  margin: 0 0 30px 0;
  border: 1px solid $gray-200;

  &.no-border {
    padding: 0 0 30px 0;
    border: 0;
  }

  &.fieldset-small {
    padding: 0;
    margin: 0 0 15px 0;
  }
}
.row > div[class^="span"] > fieldset { margin-top: 0; }
legend {
  border-bottom: 0;
  font-family: 'Udemy-Sans-Bold';
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0 -4px;
  margin-bottom: 0;
  padding: 0 4px;
  position: relative;
  text-transform: uppercase;
  width: auto;
}
legend+.control-group {
  margin-top: 0;
}
legend.p {
  font-family: 'Udemy-Sans';
  font-size: inherit;
  font-weight: 400;
  line-height: inherit;
  letter-spacing: inherit;
  margin: 0;
  padding: 0;
  text-transform: none;
}
.control-group { margin-bottom: 15px; }

label {
  font-family: 'Udemy-Sans-Bold';
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
}

label.radio, label.checkbox { margin-bottom: 15px; }
.control-group > .controls > label.checkbox, .control-group > .controls > label.radio {
  margin-bottom: 0;
}
.help-inline { font-size: 1.3rem; line-height: 15px; color: $gray-300; }
label.checkbox + .help-inline, label.radio + .help-inline { margin-left: 16px; }

form .btn,
.btn-bold {
  font-family: 'Udemy-Sans-Bold';
  font-weight: 700;
}

input[type="file"] { margin-bottom: 10px; }

/* form styling */
form label strong {
  font-family: 'Udemy-Sans-Bold';
  font-weight: 700;
}

/* Login Forms */
#custom_login_block2 {
  img { max-width: 100%; height: auto; }
}

#login-form {
  label {
    font-family: 'Udemy-Sans-Bold';
    font-weight: 700;
  }
  input[type=email],
  input[type=password] { font-size: 1.6rem; height: auto; padding: 7px 9px; }
}

#login-account-support { 
  margin: 0;

  p { margin-left: 64px; padding-top: 15px; }
  .options { clear: both; margin: 10px 0 0 64px; }
  .optionIcon { float: left; }
  .optionIcon i { text-align: center; width: 20px; }
  .optionValue { margin: 0 0 5px 25px; }
}

#login-forgot-password { margin-left: 2em; }

#login-remember {
  color: $gray-500;

  label { margin-bottom: 0; }
  .help-inline { color: $gray-500; }
} 

.radio,
.checkbox { /* override bootstrap and reset to corpu */
  label {
    font-family: 'Udemy-Sans-Bold';
    font-weight: 700;
  }
}

.form-actions {
  .btn {
    @media(max-width: $screen-sm) {
      margin-top: 5px;
      display: inline-block;
      float: none !important;
    }
  }
}

.form-group {
  input[type="text"].has-error {
    border: 1px solid $state-danger-text !important;
  }
}

/* Horizontal Form alignment modification */
.form-horizontal .control-label.label-left {
  @media (min-width: $screen-sm-min) {
    text-align: left; 
  }
}
