/* .modal-fullscreen */

#cuxModalFull{
  background-color: #fff;
}

.modal-fullscreen {
  background: transparent;
  z-index: 100001;

  &.modal-white { background-color: #fff; }
}
.modal-fullscreen .modal-content {
  background: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal-backdrop.modal-backdrop-fullscreen {
  background: #ffffff;
}
.modal-backdrop.modal-backdrop-fullscreen.in {
  opacity: .97;
  filter: alpha(opacity=97);
}

/* .modal-fullscreen size: we use Bootstrap media query breakpoints */

.modal-fullscreen .modal-dialog {
  margin: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
@media (min-width: 768px) {
  .modal-fullscreen .modal-dialog {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .modal-fullscreen .modal-dialog {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .modal-fullscreen .modal-dialog {
     width: 1170px;
  }
}

.modal-content { 
  padding: 15px;
}

.modal-header .close {
  font-size: 2.4rem;
  opacity: 1;
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal-header .close:focus {
  outline: 1px solid #1C1D1F !important;
}
