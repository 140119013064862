/*
  How it works
  Change the value of the display property with our responsive display utility classes. We purposely support only a subset of all possible values for display. Classes can be combined for various effects as you need.

  Notation
  Display utility classes that apply to all breakpoints, from xs to xl, have no breakpoint abbreviation in them. This is because those classes are applied from min-width: 0; and up, and thus are not bound by a media query. The remaining breakpoints, however, do include a breakpoint abbreviation.

  As such, the classes are named using the format:

  .d-{value} for xs
  .d-{breakpoint}-{value} for sm, md, lg, and xl.
  Where value is one of:

  none
  inline
  inline-block
  block
  table
  table-cell
  table-row
  flex
  inline-flex
  The media queries effect screen widths with the given breakpoint or larger. For example, .d-lg-none sets display: none; on both lg and xl screens.
*/

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .d#{$infix}-none         { display: none !important; }
    .d#{$infix}-inline       { display: inline !important; }
    .d#{$infix}-inline-block { display: inline-block !important; }
    .d#{$infix}-block        { display: block !important; }
    .d#{$infix}-table        { display: table !important; }
    .d#{$infix}-table-row    { display: table-row !important; }
    .d#{$infix}-table-cell   { display: table-cell !important; }
    // .d#{$infix}-flex         { display: flex !important; }
    // .d#{$infix}-inline-flex  { display: inline-flex !important; }
  }
}


/*
  Utilities for toggling `display` in print
*/

@media print {
  .d-print-none         { display: none !important; }
  .d-print-inline       { display: inline !important; }
  .d-print-inline-block { display: inline-block !important; }
  .d-print-block        { display: block !important; }
  .d-print-table        { display: table !important; }
  .d-print-table-row    { display: table-row !important; }
  .d-print-table-cell   { display: table-cell !important; }
  // .d-print-flex         { display: flex !important; }
  // .d-print-inline-flex  { display: inline-flex !important; }
}
