/*
    Set Default Footer Font Size
*/

#footer {
    background: #1c1d1f;
    border-top: 1px solid #3e4143;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 400;
    padding: 4.8rem 4.8rem 0 4.8rem;

    a {
        color: #fff;
        // text-decoration: underline;
    }

    @media(min-width: 576px) {
        .link-column {
            flex-basis: 25.6rem;
            margin-right: 1.6rem;
            margin-bottom: 0;
        }
    }

    .cc {
        margin: auto;
        margin-right: 0;
    }
}

#custom_footer {
    margin: 20px 0;
    font-size: 1.1rem;
    line-height: 13px;
}
