// .news-story { margin: 30px 0; min-height: 115px; position: relative; overflow: hidden; }
// .news-story:first-child { margin-top: 0; }
// .news-story .title { margin: 0; }
// .news-story .byline { margin: 0 0 15px 0; color: #888; }
// .news-story .__likes__ { display: inline; margin-left: 1em; }
// .news-story .thumb { position: absolute; top: 0; left: 0; }
// .news-story .story { margin-left: 115px; }
// .news-story.no-thumb .story { margin-left: 0; }

.news-story {
  & + .news-story { margin-top: $grid-gutter-width}
  .title { margin: 0; }
  ._likes_ { display: inline; margin-left: 1em; }
  .news-story .byline { margin: 0 0 15px 0; color: #888; }

  .wysiwyg { margin: 15px 0; font-size: 1.6rem; line-height: 24px; }
}

// .news-story .title { margin: 0; }
// .news-story .edit { margin: 0 0 4px 1em; }
// .news-story .byline { margin: 0 0 15px 0; color: #888; }
// .comments { clear: both; }