/* Content edited in TinyMCE should be wrapped in .wysiwyg class for CSS */
.wysiwyg { line-height: 1.75em; color: #333; }
.wysiwyg ul, .wysiwyg ol { overflow: auto; }
.wysiwyg ul li { margin: 0.5em 0 0.5em 2.5em; padding: 0; }
.wysiwyg ol li { margin: 0.5em 0 0.5em 2.5em; padding: 0; }
.wysiwyg b, .wysiwyg strong { font-family: 'Udemy-Sans-Bold'; font-weight: 700; }
.wysiwyg i, .wysiwyg em { font-style: italic; }
.wysiwyg blockquote { margin-left: 3em; }
.wysiwyg img[align=right] { margin: 10px 0 10px 20px; max-width: 100%; height: auto; }
.wysiwyg img[align=left] { margin: 10px 20px 10px 0; max-width: 100%; height: auto; }
.wysiwyg .image-full { display: block; max-width: 100%; height: auto; }
.wysiwyg .image-left { float: left; margin: 10px 30px 10px 0; max-width: 100%; height: auto;}
.wysiwyg .image-right { float: right; margin: 10px 0 10px 30px; max-width: 100%; height: auto; }
.wysiwyg iframe { max-width: 100%; /*height: auto;*/ }
.wysiwyg hr { border-color: #000; }