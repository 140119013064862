/*
	
	Overview
	These utility classes float an element to the left or right, or disable floating, based on the current viewport size using the CSS float property. !important is included to avoid specificity issues. These use the same viewport breakpoints as our grid system.

	Classes
	Toggle a float with a class:

	<div class="float-left">Float left on all viewport sizes</div><br>
	<div class="float-right">Float right on all viewport sizes</div><br>
	<div class="float-none">Don't float on all viewport sizes</div>

	Responsive
	Responsive variations also exist for each float value
	<span class="float-none float-sm-right"></span>

*/

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .float#{$infix}-left  { @include float-left; }
    .float#{$infix}-right { @include float-right; }
    .float#{$infix}-none  { @include float-none; }
  }
}