// temp variables until BS4 is running
$dark: $gray-500;
// variable file overwites
$brand-primary:         $brand-300 !default;
$brand-secondary:       $gray-500 !default;
$brand-success:         $teal-400 !default;
$brand-warning:         $orange-200 !default;
$brand-danger:          $red-400 !default;

// Splash Page Overides and new design elements. Custom components should be moved out and made global
.splash-page {
  .fas.fa-quote-left {
    top: 15px;
    font-size: 3.5rem;
    margin: auto;
    text-align: center;
    display: block;
  }

  #sel2 {
    height: 30px !important;
  }

  .lh-sm {
    line-height: 14px;
  }

  // media area (thumbnail video and sample lecture)
  .videoContainerBase.video-container-youtube>iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #video-tabs {
    position: relative;
    padding: 0 0 8px 0;
    background: none;
    border: none;

    .ui-tabs-nav {
      display: flex;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0;
      background: none;
      border: none;

      li {
        top: -1px;
        border: none;
        background: none;
        box-shadow: none;

        &.ui-tabs-active>a {
          text-decoration: underline;
          color: $brand-primary;
        }
      }
    }
  }

  .ui-tabs .ui-tabs-panel {
    padding: 0;
    background: none;
    border: none;
  }

  .row {
    padding: 6rem 35px;

    @media (max-width: 992px) {
      margin: 0 -15px;
      padding: 3rem 15px;
    }
  }

  .fas {
    line-height: 21px;
  }

  .cohort-addl-date.border-top {
    border-top: 2px solid $gray-200 !important;
  }

  @media (min-width: 576px) {
    .event-block {
      position: absolute;
      bottom: 0;
      right: 0;
      text-align: right;
    }
  }

  .enrolled-cohorts li+li {
    border-top: solid 1px $gray-300;
    padding-top: 10px;
  }

  ul.icon-list.cux-ul {
    padding-left: 5px;
  }

  ul.icon-list.cux-ul li {
    padding-left: 10px;
    margin: 10px 0;
    font-size: 1.1em;
    line-height: 1.4em;
    padding-top: 3px;
  }

  h1,
  h2,
  h3,
  h4,
  h4,
  h6,
  h1 strong,
  h2 strong,
  h3 strong,
  h4 strong,
  h4 strong,
  h6 strong {
    color: $dark !important;
    margin-bottom: 2rem;
  }

  .faq-row {
    display: flex;
    background-color: $gray;
    border-left: 3px solid $brand-primary;

    &.question {
      background-color: $white;
      border-left: 3px solid $gray;
    }

    span {
      margin: auto 0.5rem;
    }
  }

  // Course overview row & cards
  .CO {

    .card-details,
    .card-details .card-header {
      font-size: 1.2rem;
    }

    .card-details i {
      font-size: 2rem;
    }

    @media (min-width: 576px) {

      .card-details,
      .card-details .card-header {
        font-size: 1.8rem;
      }

      .card-details i {
        font-size: 3.6rem;
      }
    }

    @media (min-width: 992px) {

      .card-details,
      .card-details .card-header {
        font-size: 1.6rem;
      }

      .card-details i {
        font-size: 3.6rem;
      }
    }

    @media (min-width: 1200px) {

      .card-details,
      .card-details .card-header {
        font-size: 2rem;
      }

      .card-details i {
        font-size: 4.2rem;
      }
    }

    @media (min-width: 1600px) {

      .card-details,
      .card-details .card-header {
        font-size: 3rem;
      }

      .card-details i {
        font-size: 6rem;
      }
    }

  }

  // Learning points
  @media (min-width: 576px) {
    [data-content="Learning-Points"] ul {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;

      li {
        margin: 0rem 2rem 2rem 0rem !important;

        &.checkmarks:before {
          font-family: FontAwesome !important;
          content: "\f00c";
          margin: 0 5px 0 -35px;
          position: absolute;
          font-size: 2.5rem;
          color: $brand-300;
        }
      }

      &:last-child {
        margin-bottom: 0rem !important;
      }
    }
  }
}

[data-content="Learning-Points"] ul {
  li {
    &.checkmarks:before {
      font-family: FontAwesome !important;
      content: "\f00c";
      margin: 0 5px 0 -35px;
      position: absolute;
      font-size: 2.5rem;
      color: $brand-primary;
    }
  }
}

// temp util helper styles until BS 4 is running
.shadow {
  box-shadow: $box-shadow-after;
}

.nowrap {
  white-space: nowrap;
}

.z-high {
  z-index: 10000;
}

.vcenter-md {
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
  }
}

.vcenter-inline-md {
  @media (min-width: 992px) {
    display: inline-block;
    vertical-align: middle;
  }
}

.col-centered {
  float: none;
  margin: auto;
}

.no-link {
  color: inherit;
}

.no-link:hover {
  text-decoration: none;
  color: inherit;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.no-shadow {
  box-shadow: none !important;
}

.bg-white {
  background-color: $white !important;
}

.w-auto {
  width: auto !important;
}

.w-100 {
  width: 100%;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-md-50 {
  @media (min-width: 992px) {
    width: 50% !important;
  }
}

.w-75 {
  width: 75% !important;
}

.w-sm-75 {
  @media (min-width: 576px) {
    width: 75% !important;
  }
}

.w-md-75 {
  @media (min-width: 992px) {
    width: 75% !important;
  }
}

.bg-gray {
  background-color: $gray !important;
}

.btn-white {
  background: $white;
  color: $gray-500;
  border: 1px solid $gray-500;
}

.btn-secondary, .btn-default {
  background-color: $brand-secondary;
  color: $white;
  border: 1px solid transparent;
  border-color: darken($brand-secondary, 5%);

  &:hover,
  &:focus {
    background-color: darken($brand-secondary, 10%) !important;
  }

}
.btn-outline-primary {
  border-color: $brand-primary;
  i {
    color: $brand-primary;
  }
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
  background-color: transparent;
  
  &:hover,
  &:focus {
    border-color: darken($brand-primary, 10%);
    background-color: $brand-primary !important;
  }

}

.btn-alternative {
  background-color: $brand-primary;
  color: $white;
  border: 1px solid transparent;
  border-color: darken($brand-primary, 5%);
}

.card {
  box-shadow: 0px 2px 4px 0px rgba(75, 82, 97, 0.32);
  background-color: $white;
  padding: 1.5rem;

  .card-details {
    margin: auto;
    text-align: center;

    .card-header {
      font-size: 2.2rem;
    }
  }
}

.bg-none {
  background-color: transparent !important;
}

.d-flex {
  display: flex !important;
  display: -ms-flexbox !important;
}

.flex-col {
  display: flex !important;
  display: -ms-flexbox !important;
  align-items: flex-start;
  flex-direction: column;
}

@media (min-width: 576px) {
  .d-sm-flex {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .d-md-flex {
    display: flex !important;
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: block;
  overflow: hidden;
}

.text-dark {
  color: $dark;
}

.text-secondary {
  color: $brand-secondary;
}

.text-primary-dark {
  color: $brand-primary;
}

.text-left,
.text-align-left {
  text-align: left !important;
}

.text-align-center {
  text-align: center !important;
}

.accordion .fas {
  transition: all 0.3s ease-in-out !important;
}

.accordion,
.accordion:hover {
  text-decoration: none !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.checkbox-large {
  label {
    line-height: 1rem;

    small {
      text-transform: none;
    }
  }

  input {
    width: 25px;
    height: 25px;
    margin-left: -34px !important;
    margin-top: 2px !important;
  }
}

// slick stuff... Should PROBABLY be global, but I don't know why it's not already...
.slick-track {
  margin-left: auto;
  margin-right: auto;
}

.slick-next,
.slick-prev {
  height: 30px !important;
  margin-top: -15px !important;
}

.slick-next:before {
  font-family: FontAwesome !important;
  content: "\F105" !important;
  color: $dark !important;
  font-size: 3rem !important;
}

.slick-prev:before {
  font-family: FontAwesome !important;
  content: "\F104" !important;
  color: $dark !important;
  font-size: 3rem !important;
}

.hang-middle {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.badge.badge-secondary {
  position: absolute;
  border: 1px solid $brand-primary;
  background-color: $white;
  color: $gray-400;
  transition: all 0.3s ease-in-out;

  &.inside {
    top: 6px !important;
    right: 10px !important;
  }

  &.inline {
    position: relative !important;
    border-radius: 50% !important;
  }
  &.mobile-menu {
    top: 10px !important;
    left: 40px;
  }
}
.list-group-horizontal .list-group-item
{
	display: inline-block;
}
.list-group-horizontal .list-group-item
{
	margin-bottom: 0;
	margin-left:-4px;
	margin-right: 0;
 	border-right-width: 0;
}
.list-group-horizontal .list-group-item:first-child
{
	border-top-right-radius:0;
	border-bottom-left-radius:4px;
}
.list-group-horizontal .list-group-item:last-child
{
	border-top-right-radius:4px;
	border-bottom-left-radius:0;
	border-right-width: 1px;
}
