.cux-flex { 
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;

  .cux-tile {
    box-sizing: border-box;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    float: left;
    padding: .5em;
    width: 100%;

    @media(min-width: $screen-sm){
      width: 33%;
    }

    @media(min-width: $screen-lg){
      width: 25%;
    }

    @media(min-width: $screen-xs) and (max-width: $screen-sm){
      width: 50%;
    }

    a:hover { text-decoration: none; }

    &:hover .preview-excerpt { display: block; }
    &:hover :hover .media-image { display: none; }
  }
}

a.cux-tile {
  &:hover { text-decoration: none !important; }
}
.tile-item {
  border: 1px solid #D1D7DC;
  box-shadow: #666 0 5px 10px -5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;

  &.bg-grey { background-color: #F7F9FA; }

  &.center-center { align-items: center; justify-content: center; }

  .tile-header { 
    -ms-flex-positive: 0;
    flex-grow: 0;
    min-height: 2.5em;
    padding: .5em 1em;
  }
  .tile-content { -ms-flex-positive: 0; flex-grow: 1; min-height: 2.5em; padding: .5em 1em; background-color: #fff; }
  .tile-footer { -ms-flex-item-align: end; align-self: flex-end; background-color: #fff; box-sizing: border-box; color: #3E4143; -ms-flex-positive: 0; flex-grow: 0; padding: .5em 1em; width: 100%; }
  .tile-footer.bg-grey { background-color: #eee; }
}

/**** Slider used on User Home ****/
.owl-slider-tiles {
  .owl-stage {
    display: flex;
    flex-direction: row;
  }

  &.bg-grey .owl-item { background-color: #F7F9FA; }

  .owl-item {
    background-color: #eee;
    border: 1px solid #D1D7DC;
    border-radius: 6px;
    box-shadow: #666 0 5px 10px -5px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 5px;

    .tile-header { min-height: 1em; padding-bottom: 10px; }
  }

  .owl-stage { padding-bottom: 10px; }

  .slider-tile { min-height: 200px; }
  .slider-tile.center-center { display: flex; flex-direction: row; align-items: center; justify-content: center; }

  .slider-tile { display: flex; flex-direction: column; }
  .slider-tile .tile-header { flex-grow: 0; width: 100%; }
  .slider-tile .tile-content { flex-grow: 1; width: 100%; padding-left: 10px; padding-right: 10px; }
  .slider-tile .tile-footer { flex-grow: 0; width: 100%; align-self: end; align-content: center; }

  a.slider-tile:hover,
  a.slider-tile:focus { text-decoration: none; }
}

.tile-slider { 
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  .slider-nav {
    flex-grow: 0;
    padding: 0;
    justify-content: center;
    cursor: pointer;

    color: #ddd;

    &:hover { color: #000; }
  }
  .slider-content {
    flex-grow: 1;
    overflow: hidden;
  }

  .dropdown .dropdown-toggle {
    background-color: #eee;
    background-image: none;
    border: 0;
    color: $black;

    .caret { color: $black; }
  }
}