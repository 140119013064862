$container-padding: 30px;
$white: #fff;
$gray: #f8f8f8;
$gray-medium: #e3e3e3;
$gray-dark: #9b9b9b;
$black: #3E4143;

$critical: #cf0118;
$warning: #f7a623;
$great: #7fd220;

.tabset-basic {
  .nav { margin-bottom: 0; }

  .nav-tabs a {
    font-size: 1rem;
    text-transform: capitalize;
    // padding: 1.5em;
  }

  .nav-tabs li:first-child a {
    border-left: 0;
  }

  .nav-tabs > li > a {
    border-radius: 0;
  }

  .nav-tabs>.active>a, .nav-tabs>.active>a:hover, .nav-tabs>.active>a:focus, .nav-list>.active>a, .nav-list>.active>a:hover, .nav-list>.active>a:focus, .nav-pills>.active>a, .nav-pills>.active>a:hover, .nav-pills>.active>a:focus, .nav-pills>.active>a, .nav-pills>.active>a:hover, .nav-pills>.active>a:focus {
    background-color: inherit;
    color: $black;
    border-bottom: 1px solid $white;
    border-top: 1px solid $white;
  }

  .tab-content {
    padding: $container-padding/2;
  }
}

.tabset-white {
  background-color: $white;
  border: 1px solid $gray-medium;
}

.nav-tabs.tabs-gray {
  border-bottom: 1px solid $gray;
  margin-bottom: 0;

  > li.active > a,
  > li.active > a:hover,
  > li.active > a:focus {
    background-color: $gray;
    border-color: $gray;
    color: inherit;
  }

  > li > a:hover{
    border-color: transparent;
    background-color: $gray-medium;
  }

  /* TabDrop */
  .open .dropdown-toggle, 
  >li.dropdown.open.active>a:hover, 
  >li.dropdown.open.active>a:focus{
    color: inherit;
    background-color: $gray;
    border-color: $gray;
  }

  li.dropdown a { margin-right: 0; }

  > li > a.dropdown-toggle {
    padding-right: 8px;
  }

  .dropdown-menu>li>a:hover, 
  .dropdown-menu>li>a:focus,
  .dropdown-submenu:hover>a,
  .dropdown-submenu:focus>a {
    background-color: $gray-medium;
    background-image: none;
    color: inherit;
  }
}
/* TabDrop */
.nav.tabs-gray {
  li.dropdown.open.active .caret, 
  li.dropdown.open a:hover .caret, 
  li.dropdown.open a:focus .caret {
    border-top: 4px solid $black;
    border-bottom: 0;
  }
}

.gray-container {
  background-color: $gray;
  padding: $container-padding/2;
  box-sizing: border-box;
}