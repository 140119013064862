.progress {
    position: relative;
    font-size: 1.2rem;
    cursor: default;

    &.p-small {
        height: 5px;
    }
}
.progress .overlay {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    text-align: center;
}

.bar-header { text-transform: capitalize;}
.progress > .overlay { color: #000; background: transparent; z-index: 1; }
.progress .bar{ position: absolute; top: 0; bottom: 0; left: 0; overflow: hidden; }
.progress .bar > .overlay { display: none; color: #eee; right: auto; margin-left: 0; }
.inverted .progress-bar,
.bar.bar-secondary {
    background-color: $brand-300;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

#simple-welcome .progress .progress-overlay {
    top: 3px;
    font-size: 1.2rem !important;
    color: $gray-500;
    padding-top: 3px;
}