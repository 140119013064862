/* Alerts */
.alert {
    border-radius: 0;
    box-shadow: inset 0px 7px 7px -5px rgba(192, 152, 83, 0.2);
    padding-top: 11px;
    border-bottom-width: 0;
}

.alert-error {
    box-shadow: inset 0px 7px 7px -5px rgba(185, 74, 72, 0.2);
}

.alert-info {
    box-shadow: inset 0px 7px 7px -5px rgba(53, 135, 173, 0.2);
}

.alert-success {
    box-shadow: inset 0px 7px 7px -5px rgba(70, 136, 71, 0.2);
}

#system-alert {
    display: none;
    margin-bottom: 0;
}

#system-alert .icon-warning-sign {
    margin-right: 10px;
}

.alert h1,
.alert h2,
.alert h3,
.alert h4,
.alert h5,
.alert h6 {
    color: inherit;
    margin-top: 0;
    font-family: 'Udemy-Sans-Bold';
    font-weight: 700;
}

.alert-block h1,
.alert-block h2,
.alert-block h3,
.alert-block h4,
.alert-block h5,
.alert-block h6 {
    color: inherit;
    margin: 0;
}

/* Old browser warnings */
.oldBrowserWarning {
    margin-bottom: 0;
}