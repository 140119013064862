/* 

LEGACY 

*/

/*
* Generic tabs.
* For icon tabs, add .icon class.
*/
.tabs-container { position: relative; }
.tabs-container #notificationPreferencesLink { position: absolute; right: 10px; bottom: 10px; }
.ie7 .tabs-container #notificationPreferencesLink { clear: both; bottom: 50px; }
ul.tabs {
  position:relative;
  margin:0 0 40px 0; padding:0;
  display:block;
  border-bottom:1px solid #d1d1d1;
  border-top:1px solid #e1e1e1;
  border-left:1px solid #ccc;
  border-right:1px solid #ccc;
  background-color:#f2f2f2;
}
ul.tabs li {
  display:inline-block;
  margin:0; padding:0;
}
.ie7 ul.tabs { width: 100%; float: left; }
.ie7 ul.tabs li {
  zoom:1;
  float: left;
}
.ie7 ul.tabs>li>a { float: left; }
.ie7 ul.tabs.icon>li>a { width: 5em;}
.ie7 ul.tabs.treeviews>li>a { width: auto; }
ul.tabs li.admin { width: 145px; }
ul.tabs li.adminRight { padding:40px 20px 0 0; float:right; }
ul.tabs li select.orgpicker {  width: 300px; }
ul.tabs li a {
  position: relative;
  left: 0;
  top: 0;
  color: #666;
  display: block;
  text-decoration: none;
  padding: 8px 10px 8px 10px;
  margin: 0;
  font-size: 1rem;
  border-left: 1px solid #f9f9f9;
  border-top: 1px solid #f2f2f2;
  border-right: 1px solid #ccc;
  border-bottom: none;
  text-shadow: 1px 1px 0px #ffffff;
  width: auto;
  min-width: 5em;
  height: auto;
  text-align: center;
}
ul.tabs li.buttonPane {
  position: absolute;
  bottom: .37em; right:.37em;
}
ul.tabs li.buttonPane a.btn {
  width:160px;
  height: auto;
  padding: inherit;
}
ul.tabs.icon li a {
  padding: 55px 10px 8px 10px;
  background-position: center 10px;
  background-repeat: no-repeat;
}
ul.tabs.icon li a.resources {
  // background-image: url('../../../../assets/images/icons/resources.png');
  background-position: center 12px;
}
ul.tabs.icon li a.community {
  // background-image: url('../../../../assets/images/icons/people.png');
  background-position: center 12px;
}
ul.tabs.icon li a.calendar {
  background-image: url('../../../../assets/images/icons/calendar.png');
  background-position: center 9px;
}
ul.tabs.icon li a.course {
  background-image: url('../../../../assets/images/icons/course.png');
  background-position: center 20px;
}
ul.tabs.icon li a.files {
  background-image: url('../../../../assets/images/icons/files.png');
  background-position: center 7px;
}
ul.tabs.icon li a.bookmarks {
  background-image: url('../../../../assets/images/icons/bookmarks.png');
  background-position: center 12px;
}
ul.tabs.icon li a.discussion {
  background-image: url('../../../../assets/images/icons/discussion.png');
}
ul.tabs.icon li a.asset_library {
  // background-image: url('../../../../assets/images/icons/asset.png');
}
ul.tabs.icon li a.observer {
  // background-image: url('../../../../assets/images/icons/observer.png');
}
ul.tabs li a:hover {
  background-color:#f9f9f9;
  border-top:1px solid #f2f2f2;
}
ul.tabs li.active a,
ul.tabs li a.active {
  background-color: #e2e8ed;
  border-left:1px solid transparent;
  border-right:1px solid #e1e1e1;
  color:#444;
}
ul.tabs .active .cssArrow.down {
  position:absolute;
  bottom:-1em;
  left:50%;
  margin-left:-1.5em;
  width: 0px;
  height: 0px;
  border-right: 1.5em solid transparent;
  border-left: 1.5em solid transparent;
  border-top: 1em solid #e2e8ed;
  display:block;
}

#help_text { background-color: #F7F9FA; color: #1C1D1F; padding: 8px 35px 8px 14px; margin-bottom: 20px; }
.help-block { color: #3E4143; }

.table.dropshadow:after { content: ''; display: block; height: 1; }

.event-calendar .days { width: 25px; }
.event-calendar .today { background-color: #ff0; }
