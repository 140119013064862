/* Table styling */
.table { font-size: 1.4rem; }
.table th { background-color: #dddddd; }
.table td { background-color: #fff; }
.table th, .table td { border-top: 1px solid #ffffff; color: #1C1D1F; }
.table-bordered th, .table-bordered td { border-left: 1px solid #ffffff; }

.table.table-bold th { padding: 10px 8px; background-color: #0046BC; color: #72B8FF; text-transform: capitalize; letter-spacing: 0.13em; }
.table.table-bold td { padding: 14px 8px; }

.table .cell-center { text-align: center; }
.table .cell-right { text-align: right; }

/* Used in my_communities / my_subscriptions */
.table-organization-container { position: relative; }
.table-organization-container.organization-depth-2 { padding-left: 2%; }
.table-organization-container.organization-depth-3 { padding-left: 4%; }
.table-organization-container.organization-depth-4 { padding-left: 6%; }
.table-organization-container.organization-depth-5 { padding-left: 8%; }
.table-organization th { position: relative; }
.table-organization td.last { text-align: center; }
.table-organization tr th:first-child { padding-left: 30px; }
.table-organization td.community-name.subitem {
    padding-left: 40px;
    background-image: url('../../../../assets/images/tree-15px-lastnode.png');
    background-position: 15px -37px;
    background-repeat: no-repeat;
}
.table-organization .diagonal {
    position: absolute;
    top: 0; left: 0; width: 0; height: 0;
    border-top: 27px solid red;
    border-bottom: none;
    border-right: 27px solid transparent;
    z-index: 8;
}
.table-organization form, .table-organization form select { margin: 0; }