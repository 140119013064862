// UdLite Colors
$white: #fff;

$gray-500: #1C1D1F;
$gray-400: #3E4143;
$gray-300: #6A6F73;
$gray-200: #D1D7DC;
$gray-100: #F7F9FA;

$brand-500: #350C50;
$brand-400: #7325A3;
$brand-300: #A435F0;
$brand-200: #E1B8FC;
$brand-100: #F6EEFC;

$indigo-500: #180A3D;
$indigo-400: #371783;
$indigo-300: #5624D0;
$indigo-200: #CEC0FC;
$indigo-100: #F3F0FA;

$teal-500: #113731;
$teal-400: #1E6055;
$teal-300: #2D907F;
$teal-200: #ACD2CC;
$teal-100: #F2F7F6;

$red-500: #612012;
$red-400: #B32D0F;
$red-300: #E25D3F;
$red-200: #FCAEA0;
$red-100: #FBECE9;

$orange-500: #4D3105;
$orange-400: #B4690E;
$orange-300: #E59819;
$orange-200: #F3CA8C;
$orange-100: #FCF5E8;

$yellow-500: #3D3C0A;
$yellow-400: #98961B;
$yellow-300: #DDDB36;
$yellow-200: #ECEB98;
$yellow-100: #F9F9D7;

// BS overwrites & additional variables
$black: $gray-500;
$gray-base:             $black;

$brand-primary:         $brand-300;
$brand-secondary:       $gray-500;
$brand-success:         $teal-400;
$brand-warning:         $orange-200;
$brand-danger:          $red-400;

$state-success-text:    $teal-400;
$state-success-bg:      $teal-200;
$state-success-border:  $teal-200;

$state-info-text:       $indigo-400;
$state-info-bg:         $indigo-200;
$state-info-border:     $indigo-200;

$state-warning-text:    $gray-500;
$state-warning-bg:      $orange-200;
$state-warning-border:  $orange-200;

$state-danger-text:    $red-400;
$state-danger-bg:      $red-200;
$state-danger-border:  $red-200;

$progress-bar-danger-bg: $gray-400;

// Tabs
$menu-color: $brand-300;
$nav-tabs-border-color: $gray-500;
$nav-tabs-active-link-hover-color: $gray-500;
$nav-tabs-active-link-hover-border-color: $gray-500;

// Breakpoints
$mobile-menu-break: 992px;
$mobile-menu-break-max: 991px;

// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $mobile-menu-break;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: 991px !default;

// Modal
$modal-header-border-color: transparent;
$modal-inner-padding: 0 15px 15px;
$zindex-modal:             10000;
$zindex-modal-background:  9999;
$modal-backdrop-opacity: .85;

// Forms
$input-bg: $white;

$state-danger-text:              $red-400 !default;
$state-danger-bg:                $red-200 !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;

// Outer Chrome
$mainPadding: 35px;

// Borders
$border-width:                1px !default;
$border-color:                $gray-100 !default;

.font-weight-bold {
    font-weight: 700;
}
