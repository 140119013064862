/*

  breadcrumbs

*/
#breadcrumb-container {
  display: none;
  // overflow: auto;
  margin-bottom: 20px;
}
ul#breadcrumb-nav {
  overflow:hidden;
  margin: 0; padding: 0;
  float: left;
  display: none !important;
  width: 100%;
}
#breadcrumb-nav > li {
  float: left;
  list-style-type: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#breadcrumb-nav > li a {
  margin: 0;
  padding: 0 0 0 20px;
  text-decoration: none;
  display: block;
  background: transparent url(../../../../assets/images/raquo-gray.png) 8px 50% no-repeat;
  text-overflow: ellipsis;
  overflow: hidden;
}
#breadcrumb-nav > li:first-child a { background-image:none; padding-left: 0; }
#breadcrumb-nav > li a:hover { text-decoration:underline; cursor:pointer; }