.flex-row { display: flex; flex-wrap: wrap; }

.img-fluid { max-width: 100%; }

.alert-member-empty { margin-top: 20px; }

#member-search-form { margin: 20px 0; }
#member-tools .row { padding-top: 30px; padding-bottom: 30px; min-height: 30px; }
#member-tools .pagination { margin: 0; }

.member-card {
  width: 49%;
  margin: 1%; 
  display: flex;
  flex-direction: column;

  @media(max-width: $screen-md - 1){
    &:nth-of-type(2n) {
      margin-right: 0;  
    }

    &:nth-of-type(2n+1) {
      margin-left: 0; 
    }
  }

  @media(min-width: $screen-md){
    width: 32%;

    &:nth-of-type(3n) {
      margin-right: 0;  
    }

    &:nth-of-type(3n+1) {
      margin-left: 0; 
    }
  }

  &.dropshadow {
    background-image: url("../../../../assets/images/span4-dropshadow.png");
    background-position: 50% 100%;
    background-repeat: no-repeat;

    @media(max-width: $screen-md - 1){
      padding-bottom: 3px;
      background-size: 100% 3px;
    }
    @media(min-width: $screen-md){
     padding-bottom: 6px;
     background-size: 100% 6px;
    }
  }

  .card-header {
    flex-grow: 0;
    z-index: 1;
    margin-top: 15px;

    .card-thumb { width: 75%; max-width: 170px; display: block; margin: 0 auto; }
  }
  .card-body { 
    flex-grow: 1;
    background-color: #eee;
    padding: 70px 15px 15px 15px;
    margin-top: -70px;
  }

  .card-footer {
    flex-grow: 0;
    background-color: #eee;
    padding: 0 15px 15px 15px;
  }
}
