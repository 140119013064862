.media-right,
.media > .pull-right {
  padding-left: $grid-gutter-width/2;
}

.media-left,
.media > .pull-left {
  padding-right: $grid-gutter-width/2;
}

.img-center { margin-left: auto; margin-right: auto; }
