$box-shadow-after : 0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);

// pagination 
.pagination>li>a,
.pagination>li>a:hover,
.pagination>li>a:active,
.pagination>li>span {
  color: $indigo-300;
}

// vertical stacked tab styling for messages
// Need remove the uniqueness here and mold this and other nav-stacked to the same design...
#message_nav.nav-tabs.nav-stacked {
  > li  {
    a {  
      background-color: $gray-500;
      border: 0;
      color: $white;
    }
    &.active > a {
      background-color: $brand-300;
    }
  }
}

#my-courses_nav {

  .open>a,
  .open>a:hover,
  .open>a:focus,
  .dropdown .dropdown-toggle.btn-link:hover,
  .dropdown .dropdown-toggle.btn-link:focus {
    color: $brand-300;
    background-color: $white;
  }
}

#admin-nav,
#admin-subnav {

  .nav>li>a:hover,
  .nav>li>a:focus {
    color: $brand-300;
    background-color: $white;
  }
}

#headerWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;

  .navbar-brand,
  #main-tabs>li>a {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  @media(min-width: $mobile-menu-break) {
    &.shrink {
      .navbar-brand {
        min-height: 15px !important;
        height: 30px;
      }

      #main-tabs>li>a {
        padding: 3px 15px !important;
      }
      .badge.badge-secondary.inside {
        top: -7px !important;
      }
    }
  }

  li.nav-item.dropdown.lev-1 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .nav-sidelist {
    width: 228px;
    margin: 30px 0 0;
    padding: 0;
    background-color: $white;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, .065);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, .065);
    box-shadow: 0 1px 4px rgba(0, 0, 0, .065);
  }

  .nav-sidelist>li>a {
    display: block;
    width: 190px \9;
    margin: 0 0 -1px;
    padding: 8px 14px;
    border: 1px solid $gray-200;
  }

  .nav-sidelist>li:first-child>a {
    -webkit-border-radius: 6px 6px 0 0;
    -moz-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
  }

  .nav-sidelist>li:last-child>a {
    -webkit-border-radius: 0 0 6px 6px;
    -moz-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px;
  }

  .nav-sidelist>.active>a {
    position: relative;
    z-index: 2;
    padding: 9px 15px;
    border: 0;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .15);
    -webkit-box-shadow: inset 1px 0 0 rgba(0, 0, 0, .1), inset -1px 0 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: inset 1px 0 0 rgba(0, 0, 0, .1), inset -1px 0 0 rgba(0, 0, 0, .1);
    box-shadow: inset 1px 0 0 rgba(0, 0, 0, .1), inset -1px 0 0 rgba(0, 0, 0, .1);
  }

  /* Chevrons */
  .nav-sidelist .icon-chevron-right {
    float: right;
    margin-top: 2px;
    margin-right: -6px;
    opacity: .25;
  }

  .nav-sidelist>li>a:hover {
    background-color: $gray-100;
  }

  .nav-sidelist a:hover .icon-chevron-right {
    opacity: .5;
  }

  .nav-sidelist .active .icon-chevron-right,
  .nav-sidelist .active a:hover .icon-chevron-right {
    /*background-image: url(../img/glyphicons-halflings-white.png);*/
    opacity: 1;
  }

  .nav-pills>li>a {
    border-radius: 0;
  }

  .nav-tabs.nav-stacked {
    text-transform: lowercase;
    font-size: 1.8rem;
  }

  .nav-tabs.nav-stacked>li {
    background-color: $gray-100;
  }

  .nav-tabs.nav-stacked>li>a {
    border: 1px solid $white;
    padding: 16px 30px;
  }

  .nav-tabs.nav-stacked>.active>a:after {
    float: right;
    font-family: FontAwesome;
    font-size: 1.5rem;
    content: "\f061";
    line-height: 1em;
  }

  .nav-tabs.nav-stacked>li:first-child>a {
    -webkit-border-top-right-radius: 0;
    -moz-border-radius-topright: 0;
    border-top-right-radius: 0;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius-topleft: 0;
    border-top-left-radius: 0;
  }

  .nav-tabs.nav-stacked>li:last-child>a {
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-left-radius: 0;
  }

  .nav-tabs.nav-stacked>li>a:hover,
  .nav-tabs.nav-stacked>li>a:focus {
    border-color: $white;
    z-index: 2;
  }

  /* Course Viewer V1 / Community sidebar style tweaks for widgets */
  #community-sidebar .widget {
    margin: 30px 0 0 0;
    padding-bottom: 45px;
  }

  #community-sidebar .widget:first-child {
    margin-top: 0;
  }

  #community-sidebar .widget>h1,
  #community-sidebar .widget>h2,
  #community-sidebar .widget>h3,
  #community-sidebar .widget .widget-header>h1,
  #community-sidebar .widget .widget-header>h2,
  #community-sidebar .widget .widget-header>h3 {
    font-size: 2.2rem;
    line-height: 26px;
    text-transform: none;
    letter-spacing: normal;
    font-weight: 400;
  }

  .ie7 #community-sidebar #membersBlock>div a,
  .ie8 #community-sidebar #membersBlock>div a {
    margin: 0 15px 15px 0;
  }

  .ie7 #community-sidebar #membersBlock>div a {
    width: 64px;
    height: 64px;
  }

  /* Master v2 mobile nav */
  ul.subnav {
    display: none;
    margin: 0;
    padding: 0;
    position: relative;
    left: 0;
    min-width: 200px;
    list-style: none;
    border: 1px solid $gray-200;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
    z-index: 9999;

    -webkit-transition: all 0s ease;
    transition: all 0s ease;

    @media(min-width: $mobile-menu-break) {
      position: absolute;
    }
  }

  ul.supportSubnav {
    width: 100%;

    &.subnav {
      border: 0 !important;
    }

    @media(min-width: $mobile-menu-break) {
      right: 0;
      width: 300px;
      left: auto;
    }
  }

  ul.supportSubnav li a {
    line-height: inherit;
  }

  .inverted ul.supportSubnav li a {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  .cux-nav-flex {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media(min-width: $mobile-menu-break) {
    .cux-primary-nav-container .cux-nav-flex {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  @media(max-width: $mobile-menu-break-max) {
    .cux-primary-nav-container {
      .navbar-header {
        display: flex;
      }

      .navbar-brand {
        order: 1;
        flex: 0;
        flex-grow: 1;
        flex-shrink: 0;
      }

      .navbar-toggle {
        order: 2;
      }
    }
  }

  .cux-primary-nav-container {
    box-shadow: $box-shadow-after;

    .navbar {
      background-color: transparent;
      border: 0;
      margin-bottom: 0px !important;
    }

    .navbar-brand {
      padding: 0px;
      max-width: 100%;
      max-height: 35px;
    }

    .navbar-brand>img {
      padding-right: 15px;
      max-width: 350px;
      max-height: 100%;
      flex: 0 1 auto;
      align-self: flex-start;
      object-fit: contain;
    }

    .nav>li {
      line-height: 20px;
    }

    .dropdown-menu > li.current {
      &:hover, a:hover {
        background-color: inherit !important;
        color: inherit !important;
        text-decoration: underline !important;
      }
      > a {
          color: $gray-500 !important;
          font-family: 'Udemy-Sans-Bold';
          font-weight: 700;
      }
    }

    // li.nav-item.lev-1:last-child a {
    //   padding-right: 0px !important;
    // }

    .nav>li>a {
      padding: 20px 15px;
    }

    .navbar-nav li.first {
      min-width: 200px;
    }

    .navbar-nav li.first a {
      padding: 5px 15px;
    }

    .navbar-nav li.login {
      min-width: 200px;
    }

    .navbar-nav .subnav {
      min-width: 200px;
    }

    .navbar-nav>li>.subnav {
      border-style: solid;
      border-color: $gray-200;
      border-width: 1px 0 0 0;
      margin-top: 1px;
    }

    .navbar-nav .subnav li+li {
      border: 0;
    }

    .navbar-nav .subnav a {
      padding: 5px 15px;
      line-height: 3em;
      white-space: nowrap;
    }

    .inverted.navbar-nav>.open a,
    .inverted.navbar-nav>.open a:hover,
    .inverted.navbar-nav>.open a:focus {
      background-color: transparent;
      color: inherit;
    }

    .navbar-default .navbar-nav>li>a {
      color: inherit;
    }

    .navbar-toggle {
      padding: 10px;
      margin: auto;

      &:focus {
        outline: 5px auto #b4690e;
        outline-offset: -2px;
      }
    }


    @media(min-width: $mobile-menu-break) {
      .navbar-brand {
        display: flex;

        img {
          margin-top: auto;
          margin-bottom: auto;
          flex: 1 1 auto;
        }
      }

      .navbar-nav .subnav a {
        padding: 5px 15px;
      }
    }

    @media(max-width: $mobile-menu-break-max) {
      #primary-nav {
        border: 0;
        margin-top: 1rem !important;
        border-radius: 0 0 0 50px;
        max-width: 345px;
        position: absolute;
        right: 0;
        box-shadow: #666 0 5px 10px -5px;

        .nav {
          padding: 9px 0;
        }
      }

      .divider {
        height: 1px;
        margin: 9px 0;
        overflow: hidden;
        background-color: $gray-200;
      }

      .navbar-nav .subnav.subnav-expand-mobile {
        position: relative;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
        display: block !important;
        padding: 0;
      }

      .navbar-nav .subnav.subnav-expand-mobile>li>a,
      .nav>li>a {
        padding: 0px 15px;
        line-height: 3em;
      }
    }
  }

  .nav-tabs {
    /* used in workspace */
    margin-bottom: 15px;

    &.nav-tabs-slim {
      margin-bottom: 0;
    }

    >li>a {
      margin-right: 0;
    }
  }

  .nav-justified-mobile {

    i.cux {
      display: none;
    }

    @media (max-width: $screen-xs-max) {
      border: 0;
      font-size: 1rem !important;

      i.cux {
        font-size: 2rem;
        display: block;
        text-align: center;
        width: auto;
        padding: 5px;
      }

      >li {
        display: table-cell;
        width: 1%;
      }

      >li>a {
        margin-bottom: 0;
        text-align: center;
        border: 0 !important;
        padding: 5px !important;
        border-radius: 0;
      }

      .active>a:after {
        display: none;
      }
    }
  }

  // New AX Nav - 2021
  .navbar-nav>.mega-dropdown .megamenu {
    max-width: 770px;

  }

  .full-shadow {
    box-shadow: 0 200vh 0 200vh rgba(0, 0, 0, .5),
      0 5px 5px rgba(0, 0, 0, .5);
  }

  @media (max-width: $mobile-menu-break-max) {

    .navbar-default .navbar-nav>.open>a,
    .navbar-default .navbar-nav>.open>a:hover,
    .navbar-default .navbar-nav>.open>a:focus {
      background-color: $gray-500;
    }

    .navbar-nav {
      background-color: $gray-500;

      .nav-item,
      .nav-item>a {
        color: $white !important;
      }
    }
  }

  .r-border {
    border-right: 1px solid #6A6F73;
  }

  .nav-item>a>i.fa-caret-right {
    float: right;
    line-height: 1.5;
  }

  .dropdown-toggle:focus {
    outline: #b4690e auto 1px;
  }

  @media(min-width: $mobile-menu-break) {

    .nav-item.button a.btn-primary:hover,
    .nav-item.button a.btn-primary:focus {
      background-color: $brand-300;
    }

    .dropdown-menu>.nav-item:not(.button)>a:hover,
    .dropdown-menu>.nav-item:not(.button)>a:focus,
    .tab-pane div:hover,
    .tab-pane div:focus {
      color: $white !important;
      background: $gray-500 !important;
      text-decoration: none !important;

      a,
      small {
        color: $white !important;
      }
    }

    .course-link {
      display: block;
      width: 100%;

      &:hover {
        text-decoration: none !important;
      }
    }

    .nav-item:not(.button).active {
      background-color: $gray-500;
    }

    .nav-item:not(.button):not(.lev-1):hover {
      background-color: $gray-500;
    }

    .cux-primary-nav-container .navbar-default .navbar-nav>li:not(.button)>a:hover {
      color: $indigo-300 !important;
      background-color: transparent !important;
    }

    .navbar-nav li:hover .dropdown-menu {
      display: block;
    }

    .navbar-nav li:focus .dropdown-menu {
      display: block;
    }
  }

  .dropdown.mega-menu {
    .fa-minus {
      display: none;
    }

    &.open {
      .fa-plus {
        display: none;
      }

      .fa-minus {
        display: inline;
      }
    }

    .mod-icon {
      padding-top: 3px;
      float: right;
      padding-left: 1rem;
    }

  }

  @media(max-width: $mobile-menu-break-max) {
    .nav-item i {
      width: 40px;
      text-align: center;
    }
  }
}


/*
  Set default navigation font size
*/

.navbar {
  font-size: 1.4rem;
  font-weight: 400;

  // Set Default Font Size for Font Awesome Navigation Items
  .fa-user,
  .fa-chart-bar,
  .fa-cog {
    font-size: 2.4rem;
  }
}

// Set Default Font Size for Navigation Tabs
.nav-tabs { font-size: 1.4rem; }
