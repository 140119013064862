.widget { background-position: 50% 100%; background-repeat: no-repeat; }
.widget:last-child, .widget.last { background-image: url(); }

/*

  .widget styling

*/

/* Resets */
section .widget ul, aside .widget ul, .widget ul, .widget ul li, .widget p, .colNarrow.lightBackground .widget ul li { margin: 0; padding: 0; list-style: none; width: auto; }
section .widget ul.bulleted-list, aside .widget ul.bulleted-list, .widget ul.bulleted-list, .widget ul.bulleted-list li, .widget p, .colNarrow.lightBackground .widget ul.bulleted-list li { list-style: disc; margin: 0.75em 1.5em 0.75em 0; }
section .widget ul.bulleted-list, aside .widget ul.bulleted-list, .widget ul.bulleted-list { padding-left: 40px; }

/* Generic widget styling */
.widget {
  padding-bottom: 20px;
  margin: 0 0 15px 0;
  clear: both;
  position: relative;
}
.widget.no-bg {
  background: none !important;
}
.widget.no-border {
  background-image: none;
}

.widget:last-child {
  padding-bottom: 0;
  border-bottom: 0;
  margin-bottom: 0;
}

.widget .icon { display: none; }
.widget a { text-decoration: none; }
.widget a:hover { text-decoration: underline; }

// .widget.widget-preview { opacity: 0.6; }
// .widget.widget-preview:hover { opacity: 1; }

.widget .ellipsis { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
.widget .ellipsis-more, .widget .ellipsis-no-more { overflow: hidden; }
.widget .widget-header { clear: both; overflow: auto; padding: 10px 0; }
.ie7 .widget .widget-header { overflow: hidden; }

.widget .widget-header h3,
.widget .widget-header .h3 {
  float: left;
  font-family: 'Udemy-Sans-Bold';
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.5em;
  margin: 0;
  text-transform: capitalize;
}

.widget .widget-header a.view-all,
.widget .widget-header a.widget-share,
.widget .widget-header a.widget-manage,
.widget .widget-header .widget-manage { float: right; font-size: 1.2rem; margin-left: 1em; font-style: italic; }

.widget-content { overflow: auto; clear: both; }
/* .message is intended for "widget is empty" messages */
.widget .widget-content .message { padding: 15px 10px; font-style: italic; }
.widget .widget-content .item { clear: both; overflow: auto; padding: 6px 0; }
.widget .widget-content .subitem { padding-left: 26px; }
.widget .widget-content .byline { font-size: 1.1rem; text-transform: capitalize; color: #3E4143; vertical-align: middle; margin: 0.125em 0; background: transparent 0 50% no-repeat; }
.widget .widget-content .comments { margin-left: 0.75em; font-weight: normal; }

.widget.widget-my-communities .widget-content li:first-child,
.widget.widget-events .widget-content li:first-child,
.widget.widget-news .widget-content div:first-child,
.widget.widget-activityfeed-complex .widget-content li:first-child { margin-top: 0; }

/* Activity Feed Complex-specific CSS */
.widget.widget-activityfeed-complex .widget-content { overflow: visible; }
.widget.widget-activityfeed-complex ul.items { clear: left; margin: 0; padding: 20px 8px 20px; border-bottom: 1px dotted #ccc; }

.widget.widget-activityfeed-complex .title { margin: 0; }
.widget.widget-activityfeed-complex .activityfeed-items { clear: both; }
.widget.widget-activityfeed-complex .item { clear: left; margin: 0; padding: 20px 8px 20px; }
.widget.widget-activityfeed-complex li:last-child { border-bottom: none; }
.widget.widget-activityfeed-complex .activity-users { float: left; margin: 0 10px 0 0; }
.widget.widget-activityfeed-complex .activity-user { margin-left: 0; }
.widget.widget-activityfeed-complex .activity-details { margin-left: 85px;}
.widget.widget-activityfeed-complex .activity-preview .video-thumbnail { padding: 0 20px 0 0; vertical-align: top; float: left; }
.widget.widget-activityfeed-complex .byline { padding: 2px 0 4px 0; }

.widget.widget-breakout-group .widget-content { margin-top: 15px; }
.widget.widget-breakout-group select { margin: 0; }

.ie7 #community-sidebar #breakout_groups .btn.btn-icon,
.ie7 #community-sidebar .widget-breakout-group .btn.btn-icon { padding: 5px 0; }
.ie7 #community-sidebar #breakout_groups .btn.btn-icon i[class*='icon-'],
.ie7 #community-sidebar .widget-breakout-group .btn.btn-icon i[class*='icon-'] { display: block; width: 100%; position: relative; top: 0; margin-top: 0; left: 0; }

/* Filterbox styling */
.widget.widget-activityfeed-complex .widget-activityfeed-filterbox { float: left; width: 100%; background-color: #FDEDB1; line-height: 32px; margin: 10px 0; }
.widget.widget-activityfeed-complex .widget-activityfeed-filterbox .filters .type-filter > span, .widget.widget-activityfeed-filterbox .filters .type-filter > a { float: left; }
/* Community dropdown styling */
.widget.widget-activityfeed-complex .widget-activityfeed-filterbox .community-filter { float: left;  padding-left: 10px; }
html .dropdown-container .widget.widget-activityfeed-complex .widget-activityfeed-filterbox .community-filter.no-organization .dropdown .subnav li a,
html #content .widget.widget-activityfeed-complex .widget-activityfeed-filterbox .community-filter.no-organization .dropdown .subnav li a { padding-left: 3em; }
html .dropdown-container .widget.widget-activityfeed-complex .widget-activityfeed-filterbox .community-filter.no-organization .dropdown .subnav li.community-type-root a,
html #content .widget.widget-activityfeed-complex .widget-activityfeed-filterbox .community-filter.no-organization .dropdown .subnav li.community-type-root a { padding-left: 2em; }
html .dropdown-container .widget.widget-activityfeed-complex .widget-activityfeed-filterbox .community-filter.no-organization .dropdown .subnav li.community-type-breakout a,
html #content .widget.widget-activityfeed-complex .widget-activityfeed-filterbox .community-filter.no-organization .dropdown .subnav li.community-type-breakout a { padding-left: 5em; }
html .dropdown-container .widget.widget-activityfeed-complex .widget-activityfeed-filterbox .community-filter.has-organization .dropdown .subnav li.community-type-breakout a,
html #content .widget.widget-activityfeed-complex .widget-activityfeed-filterbox .community-filter.has-organization .dropdown .subnav li.community-type-breakout a { padding-left: 3em; }
.widget.widget-activityfeed-complex .widget-activityfeed-filterbox .community-filter .dropdown-trigger { margin: 3px 0; padding: 0 20px 0 8px; width: 300px; background-color: transparent; border: 0; }

/* Type filter styling */
.widget.widget-activityfeed-complex .type-filter .label { padding-right: 0.5em; cursor: default; }
.widget.widget-activityfeed-complex .widget-activityfeed-filterbox .type-filter { float: right; padding-right: 10px; }
.widget.widget-activityfeed-complex .widget-activityfeed-filterbox .filter-button { margin-left: 8px; }
.widget.widget-activityfeed-complex .widget-activityfeed-filterbox a.filter-button:hover { text-decoration: none; }
// .widget.widget-activityfeed-complex .widget-activityfeed-filterbox .filter-button.deactivated { opacity: 0.5 }
.widget.widget-activityfeed-complex .widget-activityfeed-filterbox .filter-button.selected { border-bottom: 2px solid #fff; padding-bottom: 4px; color: #D1D7DC; }

/* More styling */
.widget.widget-activityfeed-complex .more {
  position: absolute;
  left: 0; right: 0;
  height: 100px;
  bottom: 0;
  background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 66%, rgba(255,255,255,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(66%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 66%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 66%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 66%,rgba(255,255,255,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 66%,rgba(255,255,255,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}
.widget.widget-activityfeed-complex .more a { position: absolute; bottom: 0; width: 100%; text-align: center; }
.widget.widget-activityfeed-complex .more img { visibility: visible; position: absolute; top: 6px; right: 10px; }

/* Featured Content-specific */

/* col-narrow overrides */
// html .colNarrow .widget.widget-featured-content .item { width: 97%; padding-left: 3%; margin: 0 0 20px 0; }
// html .colNarrow .widget.widget-featured-content .title { height: auto; }

/* NEW Featured Content-specific */
// @media (max-width: 979px) {
//   .widget-featured-content-showcase .item .no-thumb-overlay { padding: 10px; }
// }

// .ie7 .widget-featured-content-showcase .item { overflow: hidden; }
// .ie7 .widget-featured-content-showcase .caption { background-color: #222; bottom: 0; }
// .ie7 .widget.widget-featured-content-showcase .__likes__ { display: none; }

// .ie8 .widget-featured-content-showcase .item:hover .title { text-decoration: none; }
// .ie8 .widget-featured-content-showcase .caption { background-color: #222; left: 1px; right: 1px; }
// .ie8 .widget-featured-content-showcase .item .caption a:hover { text-decoration: underline; }

/* Below are styling rules that apply to different quantities of items */
.widget-featured-content-showcase {
  padding-bottom: 35px;

  &.widget-featured-content-showcase-sidebar {
    padding-bottom: 20px; 
    
    .widget-content .item { width: 100%; margin-bottom: 0; }
    .widget-content .item + .item { margin-top: $grid-gutter-width/2; }
  }

  .caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: row;

    background-color: rgba(0,0,0,0.4);
    color: #fff;
    padding: 10px;

    a { color: #fff; }
    .title {
      flex-grow: 1;
      text-shadow: 1px 1px 3px #333;
    }

    .likes-and-comments { flex-grow: 0; padding-left: 0.75em; }
  }

  .item {
    position: relative;
    margin-bottom: $grid-gutter-width/2;

    &:hover { background-color: inherit; }
    &:hover .title { text-decoration: underline; }

    .no-thumb-overlay { padding: 60px; color: #888; text-align: center; font-style: italic; }
    .no-thumb-overlay { position: absolute; top: 0; right: 0; bottom: 0; left: 0; }
  }

  .likes-and-comments { /* float: right; margin-left: 7px;*/ }
  .__likes__ .like-label { display: none; }
  .__likes__ { /* float: left; */ margin: 0; }

  .download { display: none; }

  .title { line-height: 1.5em; height: 3em; text-align: left; overflow: hidden; }
  a.thumbnail-container { margin-top: 5px; }
  
  .byline { margin-bottom: 10px; }
  .comments { margin: 0; }
  
  .download a {
    float: right;
    width: 0;
    text-indent: -9999px;
     margin: 0 1em;
     padding-left: 20px;
     background: url(../../../../assets/images/community/download16.png) 0 0 no-repeat;
   }

   .widget-content {
    position: relative;

    .item { 
      box-sizing: border-box;
      padding: 0;

      .overlay {
        border: 1px solid #dddddd;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    .item img { width: 100%; height: 100%; }
    // .title { font-size: 3rem; line-height: 30px; }
    .comments {
      margin: 0;

      i { display: inline; }
    }
  }

  .widget-footer { 
    text-align: right;
    font-size: 1rem;
    margin-left: 1em;

    a { margin-left: 1em; color: #54697D; }
  }
}

.widget-featured-content-showcase.widget-featured-content-showcase-1 {
  .widget-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .item { margin: 0; }

    .item-1 { 
      width: 100%;
    }
  }
}

.widget-featured-content-showcase.widget-featured-content-showcase-3 {
  .widget-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      width: 100%;
      
      @media(min-width: $screen-sm){
        margin: 0;
      }
    }

    .item-1 { 
      @media(min-width: $screen-sm){
        width: 66%;
      }
    }

    .item-2, .item-3 { 
      @media(min-width: $screen-sm){
        width: 32%;
        height: 48%
      }
    }

    .item-2 { 
      @media(min-width: $screen-sm){
        position: absolute; top: 0; right: 0;
      }
    }
    .item-3 {
      @media(min-width: $screen-sm){
        position: absolute; bottom: 0; right: 0;
      }
    }
  }
}

.widget-featured-content-showcase.widget-featured-content-showcase-2,
.widget-featured-content-showcase.widget-featured-content-showcase-4 {

  .widget-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .item { 
      display: flex;
      flex-direction: column;
      padding: 0;
      width: 100%;

      @media(min-width: $screen-sm){ width: 49%; }
    }
  }

}
// .widget.widget-featured-content-showcase-1 .widget-content .item-1 .title,
// .widget.widget-featured-content-showcase-3 .widget-content .item-1 .title { font-size: 30px; line-height: 30px; }

// .widget.widget-featured-content-showcase-2 .item { clear: none; }
// .widget-featured-content-showcase-2 .item-1 { float: left; width: 49%; }
// .widget-featured-content-showcase-2 .item-2 { float: right; width: 49%; }

// .widget-featured-content-showcase-3 .item-1 { width: 412px; }
// .widget-featured-content-showcase-3 .item-2,
// .widget-featured-content-showcase-3 .item-3 { position: absolute; right: 0; width: 192px; }
// .widget-featured-content-showcase-3 .item-2 .no-thumb-overlay,
// .widget-featured-content-showcase-3 .item-3 .no-thumb-overlay { padding: 20px; }
// .widget-featured-content-showcase-3 .item-2 { top: 0; }
// .widget-featured-content-showcase-3 .item-3 { bottom: 0; }

// .widget.widget-featured-content-showcase-4 .widget-content .item { clear: none; }
// .widget-featured-content-showcase-4 .item-1,
// .widget-featured-content-showcase-4 .item-2,
// .widget-featured-content-showcase-4 .item-3,
// .widget-featured-content-showcase-4 .item-4 { float: left; width: 49%; margin: 0 2% 2% 0; }
// .widget-featured-content-showcase-4 .item-2,
// .widget-featured-content-showcase-4 .item-4 { margin-right: 0; }

// @media (min-width: 1200px) {
//     .widget-featured-content-showcase-3 .item-1 { width: 512px; height: auto; }
//     .widget-featured-content-showcase-3 .item-2,
//     .widget-featured-content-showcase-3 .item-3 { width: 234px; height: auto; }
// }
// @media (max-width: 979px) and (min-width: 768px) {
//     .widget-featured-content-showcase-2 .item-1,
//     .widget-featured-content-showcase-2 .item-2 { position: relative; width: 100%; margin-bottom: 15px; }
//     .widget-featured-content-showcase-2 .item-1 .title,
//     .widget-featured-content-showcase-2 .item-2 .title { font-size: 24px; line-height: 28px; }

//     .widget-featured-content-showcase-3 .item-1 { width: 100%; height: auto; }
//     .widget.widget-featured-content-showcase-3 .item.item-2,
//     .widget.widget-featured-content-showcase-3 .item.item-3 { position: relative; float: left; width: 49%; margin: 10px 2% 0 0; clear: none; }
//     .widget.widget-featured-content-showcase-3 .item.item-3 { margin-right: 0; }
// }
// @media (max-width: 767px) {
//     .widget.widget-featured-content-showcase-3 .item.item-1,
//     .widget.widget-featured-content-showcase-3 .item.item-2,
//     .widget.widget-featured-content-showcase-3 .item.item-3 { position: relative; width: 100%; margin: 0 0 15px 0; }
//     .widget.widget-featured-content-showcase-3 .item.item-1,
//     .widget.widget-featured-content-showcase-3 .item.item-2,
//     .widget.widget-featured-content-showcase-3 .item.item-3 { position: relative; width: 100%; margin: 0 0 15px 0; }
//     .widget.widget-featured-content-showcase-3 .widget-content .item-1 .title,
//     .widget.widget-featured-content-showcase-3 .widget-content .item-2 .title,
//     .widget.widget-featured-content-showcase-3 .widget-content .item-3 .title { font-size: 20px; line-height: 20px; }
// }

// .widget.widget-featured-content-showcase.widget-featured-content-showcase-sidebar .item { margin-bottom: 15px; }

/* Featured Communities */
.widget-featured-community-showcase { padding-bottom: 35px; }
.widget-featured-community-showcase .widget-content { width: 100%; }
.widget.widget-featured-community-showcase .widget-content { position: relative; }
.widget.widget-featured-community-showcase .widget-content .item { padding: 0; }

.widget-featured-community-showcase .item { position: relative; }
.widget-featured-community-showcase .item .overlay { position: absolute; top: 0; right: 0; bottom: 0; left: 0; }
.widget-featured-community-showcase .item .overlay { z-index: 10; border: 1px solid #dddddd; }

.widget-featured-community-showcase .caption {
    position: absolute; left: 0; right: 0; bottom: 0;
    background-color: rgba(0,0,0,0.4);
    color: #fff;
    padding: 10px;
}
.widget-featured-community-showcase .caption a { color: #fff; }
.widget-featured-community-showcase .caption .title { text-shadow: 1px 1px 3px #333; }
.widget-featured-community-showcase .item:hover .title { text-decoration: underline; }

.widget.widget-featured-community-showcase .widget-footer { text-align: right; font-size: 1rem; margin-left: 1em; }
.widget.widget-featured-community-showcase .widget-footer a { margin-left: 1em; color: #54697D; }

.ie7 .widget-featured-community-showcase .item { overflow: hidden; }
.ie7 .widget-featured-community-showcase .caption { background-color: #222; bottom: 0; }

.ie8 .widget-featured-community-showcase .item:hover .title { text-decoration: none; }
.ie8 .widget-featured-community-showcase .caption { background-color: #222; left: 1px; right: 1px; }
.ie8 .widget-featured-community-showcase .item .caption a:hover { text-decoration: underline; }
.widget-featured-community-showcase .item img { width: 100%; height: auto; }

.widget.widget-featured-community-showcase-2 .item { clear: none; }
.widget-featured-community-showcase-2 .item-1 { float: left; width: 49%; }
.widget-featured-community-showcase-2 .item-2 { float: right; width: 49%; }

@media (max-width: 979px) and (min-width: 768px) {
    .widget-featured-community-showcase-2 .item-1,
    .widget-featured-community-showcase-2 .item-2 { position: relative; width: 100%; margin-bottom: 15px; }
    .widget-featured-community-showcase-2 .item-1 .title,
    .widget-featured-community-showcase-2 .item-2 .title { font-size: 2.4rem; line-height: 28px; }
}


/* render_content_widget */
.widget.widget-content .item { position: relative; min-height: 80px; margin: 15px 0; }
.widget.widget-content .title { margin: 0; }
.widget.widget-content .item .is-featured { margin-left: 0.5em; font-size: 1rem; line-height: 10px; font-weight: normal; color: #1C1D1F; background-color: #CEC0FC; border: 1px solid #fe6; border-radius: 4px; padding: 1px 3px; white-space: nowrap; cursor: default; vertical-align: middle; }
.widget.widget-content .thumb { float: left; }
.widget.widget-content .story { margin-left: 85px;  }
.widget.widget-content .item.no-thumb { min-height: 0; }
.widget.widget-content .no-thumb .story { margin-left: 0; }
.widget.widget-content .comments-likes { float: right; }
.widget.widget-content .byline .comments { margin-right: 1em; }
.widget.widget-content .byline .__likes__ { display: inline; }
.widget.widget-content .byline .__likes__ .like-label { display: none; }

.widget.widget-activityfeed .activity-users { float: right; margin-top: 5px; margin-left: 10px; }
.widget.widget-activityfeed .activity-user { margin-left: 8px; }

.widget.widget-links .title { vertical-align: middle; line-height: 1.5em;}
.widget.widget-links .description,
.widget.widget-attachments .description { max-height: 3em; line-height: 1.5em;  }
.widget.widget-attachments li a.file-icon { display:block; line-height:1.5em; background-repeat: no-repeat; padding-left: 24px; background-position: left top; }

.widget.widget-recent-discussions .item { margin: 20px 0; }
.widget.widget-recent-discussions .item:first-child { margin-top: 0; }
.widget.widget-recent-discussions .thumb { float: left; }
.widget.widget-recent-discussions .item-contents { margin-left: 95px; }
.widget.widget-recent-discussions h3.title { margin: 0 0 5px 0; }
.widget.widget-recent-discussions .comments { float: right; margin-left: 30px; }
.widget.widget-recent-discussions .post-body { margin: 0 0 5px 0; }

.widget.widget-new-discussion form,
.widget.widget-new-discussion form fieldset { margin-bottom: 0; }
.widget.widget-new-discussion img,
.widget.widget-new-discussion .leader-avatar { float: left; margin-right: 20px; }
.widget.widget-new-discussion .leader-avatar img { float: none; margin-right: 0; }
.widget.widget-new-discussion label { width: 15%; text-align: left; width: auto; }
.widget.widget-new-discussion li { margin: 10px 0; }
.widget.widget-new-discussion li:first-child { margin: 0; }
.widget.widget-new-discussion textarea { height: 80px; }
#newDiscussion > fieldset { border: 0; }

.widget.widget-news .item { position: relative; min-height: 100px; padding: 15px 0; }
.widget.widget-news .thumb { position: absolute; left: 0; top: 10px; width: 100px; text-align: center; }
.widget.widget-news .story { margin-left: 115px; }
.widget.widget-news .no-thumb .story { margin-left: 0; }
.widget.widget-news .story h3 { margin: 0; }
.widget.widget-news .byline .__likes__ { display: inline; margin-left: 0.5em; }
.widget.widget-news .byline .__likes__ .like-label { display: none; }
.widget.widget-news .item { overflow: hidden; }

.widget.widget-my-courses .progressBarInstance { float: right; height: auto; }
.widget.widget-my-courses dl.progress { margin: 0; }
.widget.widget-my-courses .progressBarInstance dl.progress dd.progressBar { position: relative; }
.widget.widget-my-courses .progressBarInstance dl.progress dd.progressBar .percentage-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  vertical-align: middle;
  font-family: 'Udemy-Sans-Bold';
  font-weight: 700;
  color: #ffffff;
  text-shadow: 1px 1px 2px #000000;
  filter: dropshadow(color=#000000, offx=1, offy=1);
}
html #sidebar .widget.widget-my-courses dl.progress dd.progressBar { 
    width: 50px; background: #666; margin-left: 10px;
    -moz-border-radius: 8px; -webkit-border-radius: 8px; border-radius: 8px;
}
html #sidebar .widget.widget-my-courses li.item.has-icon a.title { position: relative; padding-left: 27px; }
html #sidebar .widget.widget-my-courses li.item.has-icon a.title span.org-icon { position: absolute; top: 0; left: 0; width: 20px; height: 20px; }
html #sidebar .widget.widget-my-courses li.item a.title span.course-org { margin-left: 0; }

.widget.widget-member-block .widget-content .description { font-size: 1.2rem; text-align: justify; margin: 0.5em 0; color: #6A6F73; line-height: 1.25em; }

.widget.widget-member-block .widget-items a { float: left; padding: 5px; }

/* Adjust padding/number of users so that full member blocks always end in even rows */
@media (min-width: 1200px) {
  .widget.widget-member-block .widget-items a:nth-child(28) { display: none; }
}
@media (max-width: 979px) and (min-width: 768px) {
  .widget.widget-member-block .widget-items a { padding: 4px; }
  .widget.widget-member-block .widget-items a:nth-child(26),
  .widget.widget-member-block .widget-items a:nth-child(27),
  .widget.widget-member-block .widget-items a:nth-child(28) { display: none; }
}

.widget.widget-image-gallery .widget-content a { margin: 5px; }

.widget.widget-browse-topics .widget-content li:first-child { margin-top: 5px; }
.widget.widget-browse-topics .widget-content li { margin: 1em 0; line-height: 1.25em; }

.widget.widget-list-communities .widget-items { overflow: auto; }
.widget.widget-list-communities .widget-item { position: relative; background-color: #333; float: left; top: 0; width: 210px; height: 160px; border: 1px solid #fff; overflow: hidden; }
.widget.widget-list-communities .widget-item a { position: absolute; top: 0; right: 0; bottom: 0; left: 0; cursor: pointer ;}
.widget.widget-list-communities .intro { margin-bottom: 4px; }
.widget.widget-list-communities .widget-item .community-name { position: absolute; bottom: 0; right: 0; left: 0; padding: 6px; color: #fff; font-size: 1.6rem; font-weight: bold; background: rgba(0,0,0,0.3); text-shadow: black 0.1em 0.1em 0.2em; }
.widget.widget-list-communities .widget-item a:hover .community-name { text-decoration: underline; background-color: rgba(0,0,0,0.8); }


/* instructors.html sidebar styling */
.featured-users .featured-user-list > li { overflow: auto; margin: 15px 0; }
.featured-users .user-thumbnail { float: left; }
.featured-users .user-details { display: block; }
.featured-users .user-details .name { font-family: 'Udemy-Sans-Bold'; font-weight: 700; }
.featured-users .featured-user-container-large .user-details { padding-top: 10px; margin-left: 80px; }
.featured-users .featured-user-container-small .featured-user-list li { min-height: 32px; font-size: 1.1rem; line-height: 1.4em; }
.featured-users .featured-user-container-small .featured-user-list .user-thumbnail img { width: 32px; height: auto; }
.featured-users .featured-user-container-small .featured-user-list .user-details { margin-left: 45px; overflow: hidden; }
.widget.widget-featured-users .featured-user-container h3 { margin: 0; }
.widget.widget-featured-users .widget-header { margin: 0; }
.widget.widget-featured-users .featured-user-container .featured-user-list>li { padding: 5px; }
.widget.widget-featured-users .featured-user-container .featured-user-list .thumb { top: 6px; left: 6px; }
.widget.widget-featured-users .featured-user-container .widget-content.featured-user-list a.overlay { position: absolute; top: 0; right: 0; bottom: 0; left: 0; }
.widget.widget-featured-users .featured-user-container .widget-content.featured-user-list .user-details { font-size: 1.4rem; }
.widget.widget-featured-users .featured-user-container .widget-content.featured-user-list .user-details .title { font-size: 1.3rem; font-weight: normal; }
.widget.widget-featured-users .featured-user-container.featured-user-container-glossy .widget-header { display: none; }
.widget.widget-featured-users .featured-user-container.featured-user-container-glossy .featured-user-list>li {
  background: #e6f6fd; /* Old browsers */
  background: -moz-linear-gradient(top,  #e6f6fd 0%, #f7fbff 61%, #fafdff 62%, #fbfeff 70%, #ffffff 73%, #ffffff 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#e6f6fd), color-stop(61%,#f7fbff), color-stop(62%,#fafdff), color-stop(70%,#fbfeff), color-stop(73%,#ffffff), color-stop(100%,#ffffff)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #e6f6fd 0%,#f7fbff 61%,#fafdff 62%,#fbfeff 70%,#ffffff 73%,#ffffff 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #e6f6fd 0%,#f7fbff 61%,#fafdff 62%,#fbfeff 70%,#ffffff 73%,#ffffff 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #e6f6fd 0%,#f7fbff 61%,#fafdff 62%,#fbfeff 70%,#ffffff 73%,#ffffff 100%); /* IE10+ */
  background: linear-gradient(to bottom,  #e6f6fd 0%,#f7fbff 61%,#fafdff 62%,#fbfeff 70%,#ffffff 73%,#ffffff 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6f6fd', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
  border: 1px solid #e0e0e0;
}
.widget.widget-featured-users .featured-user-container.featured-user-container-glossy .widget-content.featured-user-list .user-details { font-size: 1.6rem; }
.widget.widget-featured-users .featured-user-container.featured-user-container-glossy .widget-content.featured-user-list .user-details .name { color: #0085AF; }
.widget.widget-featured-users .featured-user-container.featured-user-container-glossy .widget-content.featured-user-list .user-details .title { font-family: 'Udemy-Sans-Bold'; font-size: 1.4rem; font-weight: 700; color: #333; margin-top: 0.3em; }

#colnarrow .widget.widget-notifications,
.widget.widget-notifications { margin: 15px 0; }

#membersBlock > div { overflow: auto; }
#membersBlock > div > a { float: left; margin: 0 7px 7px 0; }
@media (min-width: 1200px) {
  #membersBlock > div > a { margin: 0 16px 16px 0; }
  #membersBlock > div > a:nth-child(4n+0) { margin-right: 0; }
}
@media (min-width: 980px) and (max-width: 1199px) {
  #membersBlock > div > a { margin: 0 20px 20px 0; }
  #membersBlock > div > a:nth-child(3n+0) { margin-right: 0; }
}
@media (min-width: 769px) and (max-width: 979px) {
  #membersBlock > div > a { margin: 9px 9px; }
}

#profile dl.dl-horizontal dt, #profile dl.dl-horizontal dd { margin-bottom: 0.5em; }
#profile .dl-horizontal dt { width: 7em; }
#profile .dl-horizontal dd { margin-left: 8em; }



/*
  New Discussion widget 
*/
// .collapsed { display:none; }
form label.placeholder { display:none !important; }