#blackout-date-picker {
    .datepicker table tr td.active.active {
        background-color: $brand-300;
        background-image: none;
    }
    .datepicker table tr td.today.disabled {
        background-color: $white;
        outline: 1px solid $gray-500;
        background-image: none;
    }
    .datepicker table tr td.day:hover, .datepicker table tr td.day.focused {
        background-color: $indigo-200;
    }
}