/*
    Set Default Udemy Heading Styles
*/

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'SuisseWorks', Georgia, Times, 'times new roman', serif, 'apple color emoji', 'segoe ui emoji', 'segoe ui symbol';
  font-weight: normal;
}
h1, .h1 {
  font-size: 3.2rem;
  font-weight: 700;
  letter-spacing: -.05rem;
  line-height: 1.25;
}
h2, .h2 {
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: -.02rem;
  line-height: 1.35;
}
h3, .h3 {
  font-size: 1.9rem;
  font-weight: 700;
  letter-spacing: 0rem;
}
h4, .h4 {
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0rem;
}
h5, .h5 {
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0rem;
}
h6, .h6 {
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0rem;
}

/* Heading Utilities */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  &.ud-heading-sans { font-family: 'Udemy-Sans-Bold' !important; }
  &.ud-heading-xxxl { font-size: 4rem !important; }
  &.ud-heading-xxl { font-size: 3.2rem !important; }
  &.ud-heading-xl { font-size: 2.4rem !important; }
  &.ud-heading-lg { font-size: 1.9rem !important; }
  &.ud-heading-md { font-size: 1.6rem !important; }
  &.ud-heading-sm { font-size: 1.4rem !important; }
  &.ud-heading-xs { font-size: 1.2rem !important; }
  &.ud-heading-uppercase { text-transform: uppercase !important; }
  &.ud-heading-lowercase { text-transform: lowercase !important; }
  &.ud-heading-capitalize { text-transform: capitalize !important; }
}

.ud-sans {
  font-family: 'Udemy-Sans' !important;
  font-weight: 400;
}
.ud-sans-bold {
  font-family: 'Udemy-Sans-Bold' !important;
  font-weight: 700;
}
.ud-serif {
  font-family: 'SuisseWorks' !important;
  font-weight: 700;
}

strong, b {
  font-family: 'Udemy-Sans-Bold';
  font-weight: 700;
}

a {
  color: #5524D0;

  &:hover,
  &:focus {
    color: inherit;
  }
}

p { margin: 0 0 14px; }

/* Table Headings (Utilities) */
thead, tr, td {
  &.ud-heading-sans { font-family: 'Udemy-Sans-Bold' !important; }
}

@media (max-width: $screen-xs-max) {
  // Align text to center.
  .text-xs-center {
    text-align: center;
  }

  .text-xs-right {
    text-align: right;
  }

  .text-xs-left {
    text-align: left;
  }
}

@media (min-width: $screen-sm-min) {
  // Align text to center.
  .text-sm-center {
    text-align: center;
  }

  .text-sm-right {
    text-align: right;
  }

  .text-sm-left {
    text-align: left;
  } 
}

@media (min-width: $screen-md-min) {
  // Align text to center.
  .text-md-center {
    text-align: center;
  }

  .text-md-right {
    text-align: right;
  }

  .text-md-left {
    text-align: left;
  } 
}

@media (min-width: $screen-lg-min) {
  // Align text to center.
  .text-lg-center {
    text-align: center;
  }

  .text-lg-right {
    text-align: right;
  }

  .text-lg-left {
    text-align: left;
  }
}
