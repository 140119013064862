.shaded,
#sidebar,
.nav-tabs.nav-stacked>li,
.table td {
    background-color: #F7F9FA;
    color: #333333;
}

.tile-item>.tile-header {
    background-color: #A435F0;
    color: #FFFFFF;
}

.color-secondary-inverse {
    background-color: #A435F0 !important;
    color: #FFFFFF !important;
}
