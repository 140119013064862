.mce-container {
    overflow-x: hidden;

    .text-muted {
        color: #6A6F73!important;
    }
    .text-danger {
        color: #b94a48!important;
    }
}

.form-group {
    .hidden-without-error { display: none; }
    &.has-error {
        .hidden-without-error { display: block; }
    }
}
.form-group{ 
    .mce-panel {
        border: 0px solid #1c1d1f;
        background-color: #fff;
    }
    .mce-btn {
        background: transparent;
    }
    .mce-ico {
        color: #1c1d1f;
        background: transparent;
    }
}
// @media (max-width: $screen-xs) {
//     .mce-window {
//         max-width: 320px !important;
//         left: calc(50% - 160px) !important;
//     }
//     .mce-window-body {
//         max-width: 100% !important;
//         min-height: 230px !important;
//     }
//     .mce-container.mce-panel.mce-abs-layout-item,
//     .mce-container.mce-panel.mce-abs-layout-item .mce-container-body.mce-abs-layout {
//         max-width: 100% !important;
//         min-height: 190px !important;
//     }
// //     .mce-flow-layout {
// //         // text-align: center !important;
// //     }
//     .mce-flow-layout-item.mce-btn-group {
//         border-left: none !important;
//     }
//     .mce-panel,
//     .mce-panel > .mce-container-body,
//     .mce-foot,
//     .mce-foot > .mce-abs-layout {
//         max-width: 320px !important;
// //         // text-align: center;
//     }
//     .mce-formitem {
//         width: 300px !important;
//         left: 10px !important;
//         display: block !important;
//     }
//     .mce-formitem:nth-child(2) {
//         top: 10px !important;
//     }
//     .mce-formitem:nth-child(3) {
//         top: 50px !important;
//     }
//     .mce-formitem:nth-child(4) {
//         top: 90px !important;
//     }
//     .mce-formitem:nth-child(5) {
//         top: 130px !important;
//     }
//     .mce-formitem:nth-child(6) {
//         top: 170px !important;
//     }
//     .mce-container.mce-form.mce-abs-layout-item .mce-container-body.mce-abs-layout .mce-container.mce-form.mce-abs-layout-item {
//         top: 40px !important;
//         left: 0 !important;
//         height: 160px !important;
//     }
//     .mce-formitem label {
//         width: 106px !important;
//         font-size: 13px !important;
//         left: 0px !important;
//     }
//     .mce-multiline {
//         max-width: 290px !important;
//     }
//     .mce-checkbox {
//         top: 40px !important;
//         left: 0 !important;
//     }
//     .mce-label {
//         font-size: 13px !important;
//     }
//     .mce-foot .mce-btn-has-text {
//         position: relative !important;
//         top: 0 !important;
//         left: 0 !important;
//         display: inline-block !important;
//         margin: 10px 5px !important;
//     }
//     .mce-abs-layout-item input {
//         max-width: 150px !important;
//     }
//     .mce-abs-layout-item.mce-has-open input {
//         max-width: 118px !important;
//     }
// }
