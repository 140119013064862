button {
  font-family: 'Udemy-Sans-Bold';
  font-weight: 700;
  text-transform: uppercase;
}

/*

  BOOTSTRAP BUTTON CODE 
  
  I need to override some of Bootstrap's default button styling, but can't do it in the order that I need to 
  to keep Bootstrap functioning correctly, so I had to pull a chunk of CSS into our master.css.  I marked 
  the section(s) I added with CORPU comments

*/

.btn {
  border: 1px solid #cccccc;
  *border: 0;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-bottom-color: #b3b3b3;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  *display: inline;
  font-family: 'Udemy-Sans-Bold';
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 0;
  *margin-left: .3em;
  padding: 4px 12px;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  text-transform: uppercase;
  vertical-align: middle;
  white-space: normal;
  word-wrap: break-word;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#ffe6e6e6', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  *zoom: 1;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
  color: #333333;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
}

.btn:active,
.btn.active {
  background-color: #cccccc \9;
}

.btn:first-child {
  *margin-left: 0;
}

.btn:hover,
.btn:focus {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
     -moz-transition: background-position 0.1s linear;
       -o-transition: background-position 0.1s linear;
          transition: background-position 0.1s linear;
}

.btn:focus {
  outline: 5px auto $orange-400;
  outline-offset: 0;
}

.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
          box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.btn.disabled,
.btn[disabled] {
  // cursor: default;
  background-image: none;
  opacity: 0.45;
  filter: alpha(opacity=45);
  -webkit-box-shadow: none;
     -moz-box-shadow: none;
          box-shadow: none;

  &:hover {
    text-decoration: none; 
  }
}

/* UDEMY */
.inverted .btn,
.btn {
  background-color: $gray-400;
  color: $white;
  text-shadow: none;
}
.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.btn.disabled, /*.btn.disabled:hover,*/
.btn[disabled], /* .btn[disabled]:hover,*/
.btn.working {
  color: $white;
  background-color: $gray-400;
  text-shadow: none;
}
.btn.working, button.working { cursor: wait; }
/* END UDEMY */

.btn-large {
  padding: 11px 19px;
  font-size: 1.75rem;
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;
}

.btn-large [class^="icon-"],
.btn-large [class*=" icon-"] {
  margin-top: 4px;
}

.btn-small {
  padding: 2px 10px;
  font-size: 1.2rem;
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
}

.btn-small [class^="icon-"],
.btn-small [class*=" icon-"] {
  margin-top: 0;
}

.btn-mini [class^="icon-"],
.btn-mini [class*=" icon-"] {
  margin-top: -1px;
}

.btn-mini {
  padding: 0 6px;
  font-size: 1rem;
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
}

.btn-block {
  display: block;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.btn-primary.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active {
  color: rgba(255, 255, 255, 0.75);
}

.btn-primary {
  color: $white;
  background-color: $brand-300;
  border-color: $brand-300;
}

.btn.btn-danger {
  color: $white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: $red-400;
  border-color: $red-400;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled, .btn-primary.disabled:hover,
.btn-primary[disabled], .btn-primary[disabled]:hover {
  color: $white;
  background-color: $brand-400;
}

.btn-primary:active,
.btn-primary.active {
  background-color: $brand-400 \9;
}

.btn-warning {
  color: $white;
  background-color: $orange-300;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.disabled, .btn-warning.disabled:hover,
.btn-warning[disabled], .btn-warning[disabled]:hover {
  color: $white;
  background-color: $orange-300;
}

.btn-warning:active,
.btn-warning.active {
  background-color: $orange-300 \9;
}

.btn-danger {
  color: $white;
  background-color: $red-400;
  background-repeat: repeat-x;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.disabled, .btn-danger.disabled:hover,
.btn-danger[disabled], .btn-danger[disabled]:hover {
  color: $white;
  background-color: #bd362f;
  *background-color: #a9302a;
}

.btn-danger:active,
.btn-danger.active {
  background-color: #942a25 \9;
}

.btn-success {
  color: $white;
  background-color: $teal-300;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.disabled, .btn-success.disabled:hover,
.btn-success[disabled], .btn-success[disabled]:hover {
  color: $white;
  background-color: $teal-200;
  *background-color: $teal-200;
}

.btn-success:active,
.btn-success.active {
  background-color: #408140 \9;
}

.btn-info {
  color: $white;
  background-color: $indigo-200;
  border-color: $indigo-200;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.disabled, .btn-info.disabled:hover,
.btn-info[disabled], .btn-info[disabled]:hover {
  color: $white;
  background-color: $indigo-200;
  *background-color: $indigo-200;
  border-color: $indigo-200;
}

.btn-info:active,
.btn-info.active {
  background-color: $indigo-200 \9;
}

.btn-inverse {
  color: $white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #363636;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.disabled, .btn-inverse.disabled:hover,
.btn-inverse[disabled], .btn-inverse[disabled]:hover {
  color: $white;
  background-color: #222222;
  *background-color: #151515;
}

.btn-inverse:active,
.btn-inverse.active {
  background-color: #080808 \9;
}

/* CORPU */
.btn {
    -webkit-border-radius: 0;
       -moz-border-radius: 0;
            border-radius: 0;
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
}
.btn:hover { background-image: url('../../../../assets/images/button-transparency-90deg.png'); }
.btn:active { background-image: url('../../../../assets/images/button-transparency-90deg.png'); padding-top: 6px; padding-bottom: 2px; }
.btn-large:active { padding-top: 13px; padding-bottom: 9px; }
.btn-small:active { padding-top: 3px; padding-bottom: 1px; }
.btn-mini:active { padding-top: 0; padding-bottom: 0; }
/* END CORPU */

button.btn,
input[type="submit"].btn {
  *padding-top: 3px;
  *padding-bottom: 3px;
}

button.btn::-moz-focus-inner,
input[type="submit"].btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}

button.btn.btn-large,
input[type="submit"].btn.btn-large {
  *padding-top: 7px;
  *padding-bottom: 7px;
}

button.btn.btn-small,
input[type="submit"].btn.btn-small {
  *padding-top: 3px;
  *padding-bottom: 3px;
}

button.btn.btn-mini,
input[type="submit"].btn.btn-mini {
  *padding-top: 1px;
  *padding-bottom: 1px;
}

.btn-link,
.btn-link:active,
.btn-link[disabled] {
  background-color: transparent;
  background-image: none;
  -webkit-box-shadow: none;
     -moz-box-shadow: none;
          box-shadow: none;
          color: $gray-300;
}

.btn-link {
  color: $brand-300;
  cursor: pointer;
  border-color: transparent;
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
  text-transform: none;
}

.btn-link:hover,
.btn-link:focus {
  color: #005580;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link[disabled]:hover,
.btn-link[disabled]:focus {
  color: #333333;
  text-decoration: none;
}
/* END BOOTSTRAP  */

.btn-large {
  letter-spacing: -.02rem;
}
.btn-small,
.btn-mini {
  text-transform: lowercase;
}
.btn-wide {
  padding-left: 2em;
  padding-right: 2em;
}

.btn-group>.btn:last-child,
.btn-group>.dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group>.btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* This styling really only affects the course viewer v1 buttons that take users to/from
  their breakout group, and is confined to #sidebar for the time being, unless this
  becomes a button style we want to support other places */
.btn.btn-icon {
  position: relative;
}
.btn.btn-icon:hover i[class*='icon-'] {
  text-decoration: none;
}
.btn.btn-icon.btn-icon-3x {
  padding-left: 3.5em;
}
.btn.btn-icon i[class*='icon-'] {
  position: absolute;
  left: 5px;
  top: 50%;
  margin-top: -0.5em;
}


/* Mobile BTN Sizing */
@media (max-width: $screen-xs-max) {
    .btn-xs-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-xs-block,
    input[type="reset"].btn-xs-block,
    input[type="button"].btn-xs-block {
        width: 100%;
    }
    .btn-block + .btn-xs-block,
    .btn-xs-block + .btn-block,
    .btn-xs-block + .btn-xs-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .btn-sm-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-sm-block,
    input[type="reset"].btn-sm-block,
    input[type="button"].btn-sm-block {
        width: 100%;
    }
    .btn-block + .btn-sm-block,
    .btn-sm-block + .btn-block,
    .btn-sm-block + .btn-sm-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .btn-md-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-md-block,
    input[type="reset"].btn-md-block,
    input[type="button"].btn-md-block {
        width: 100%;
    }
    .btn-block + .btn-md-block,
    .btn-md-block + .btn-block,
    .btn-md-block + .btn-md-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: $screen-lg-min) {
    .btn-lg-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-lg-block,
    input[type="reset"].btn-lg-block,
    input[type="button"].btn-lg-block {
        width: 100%;
    }
    .btn-block + .btn-lg-block,
    .btn-lg-block + .btn-block,
    .btn-lg-block + .btn-lg-block {
        margin-top: 0.5rem;
    }
}

.btn-block-xs {
  @media(max-width: $screen-xs){
    display: block;
  }
}

.btn-block-sm {
  @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    display: block;
  }
}

.btn-block-md {
  @media(min-width: $screen-md-min) and (max-width: $screen-md-max){
    display: block;
  }
}

.btn-block-lg {
  @media(min-width: $screen-lg){
    display: block;
  }
}
