@mixin shadow ($size: 12, $list: '.dropshadow') {
  #{$list}{
    background-image: url('../../../../assets/images/span#{$size}-dropshadow.png');
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: 95% 6px;
  }
}

@mixin make-shadow-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}", $subclass: ".dropshadow") {
  @for $i from (1 + 1) through $grid-columns {
    $list: ".col-xs-#{$i} #{$subclass}, .col-sm-#{$i} #{$subclass}, .col-md-#{$i} #{$subclass}, .col-lg-#{$i} #{$subclass}";
    @include shadow(#{$i}, #{$list});
  }

  @include shadow(#{$i})
}

@include shadow($list: '.dropshadow');

@include make-shadow-columns($i:1, $subclass: '.dropshadow');
@include make-shadow-columns($i:1, $subclass: ".widget");

.table.dropshadow:after{
  padding-bottom: 6px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}